import React from 'react';
import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { RegistrationError, RegistrationResult } from 'common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-5/common-utilities';
import {
    CleaveInput,
    InfoIcon,
    preventSubmit,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    ValidatedCheckbox,
    ValidatedInput,
} from '@cp-shared-5/frontend-ui';
import { personIdentificationFormValidationSchema } from './validation';
import { handleSubmit } from './utils';
import { PersonFormProps } from '../types';
import { legalNoticePath, privacyPolicyPath } from '../../../../navigation/paths';
import { useTrackerOnBeforeUnload } from '../../../../../hooks';
import { isEmpty } from 'lodash';

type PersonIdentificationFormProps = {
    onSubmitClick: Function;
    onSuccess: (data: RegistrationResult, formData: PersonFormProps) => void;
    onError: (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => void;
};

export const PersonIdentificationForm: React.FC<PersonIdentificationFormProps> = ({
    onSubmitClick,
    onSuccess,
    onError,
}) => {
    const { t } = useTranslation('registration');
    const translationPrefix = 'registration-form.person.input-labels';
    const setLastTouchedField = useTrackerOnBeforeUnload();

    const { onTyping, onSubmit: trackOnSubmit } = useAnalyticsFormTracker({
        startTyping: 'onStartTypingConfirmIdentity',
        confirm: 'onConfirmConfirmIdentiy',
    });

    const { onAction } = useAnalyticsActionTracker('onFormValidationErrorConfirmIdentity');
    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);
    const initialListErrors =
        'privateCustomerNumber, firstName, surname, dateOfBirth, confirmTermsAndConditions, confirmPrivacyPolicy';

    const onSubmit = (formValues: PersonFormProps): void => {
        onSubmitClick();
        trackOnSubmit();
        handleSubmit(formValues, onSuccess, onError);
    };

    const labels = {
        privateCustomerNumber: {
            text: `${t(`${translationPrefix}.customer-number.label`)} `,
            tooltip: t(`${translationPrefix}.customer-number.tooltip`),
        },
        firstName: t(`${translationPrefix}.name`),
        surname: t(`${translationPrefix}.surname`),
        dateOfBirth: {
            text: `${t(`${translationPrefix}.birth-date.label`)} `,
            tooltip: t(`${translationPrefix}.birth-date.tooltip`),
        },
        confirmTermsAndConditions: {
            text: t(`${translationPrefix}.agreements.terms-and-conditions.label`),
            link: t(`${translationPrefix}.agreements.terms-and-conditions.link`),
            tooltip: t(`${translationPrefix}.agreements.terms-and-conditions.tooltip`),
        },
        confirmPrivacyPolicy: {
            text: t(`${translationPrefix}.agreements.privacy-policy.label`),
            link: t(`${translationPrefix}.agreements.privacy-policy.link`),
            tooltip: t(`${translationPrefix}.agreements.privacy-policy.tooltip`),
        },
    };

    return (
        <Layout.Item>
            <Formik
                initialValues={{
                    privateCustomerNumber: '',
                    firstName: '',
                    surname: '',
                    dateOfBirth: '',
                    confirmTermsAndConditions: false,
                    confirmPrivacyPolicy: false,
                }}
                validationSchema={personIdentificationFormValidationSchema(t)}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form
                        onSubmit={preventSubmit()}
                        onChange={() => {
                            onTyping(formik.errors, formik.touched);
                        }}
                    >
                        <Fieldset>
                            <Fieldset.Row>
                                <CleaveInput
                                    cleaveOptions={{
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={labels.privateCustomerNumber.text}
                                    tooltip={labels.privateCustomerNumber.tooltip}
                                    name={'privateCustomerNumber'}
                                    testId={'private-customer-number'}
                                    onFocus={() => setLastTouchedField(labels.privateCustomerNumber.text)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInput
                                    label={labels.firstName}
                                    name="firstName"
                                    testId={'first-name'}
                                    onFocus={() => setLastTouchedField(labels.firstName)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInput
                                    label={labels.surname}
                                    name="surname"
                                    testId={'surname'}
                                    onFocus={() => setLastTouchedField(labels.surname)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <CleaveInput
                                    cleaveOptions={{
                                        delimiter: '.',
                                        blocks: [2, 2, 4],
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={labels.dateOfBirth.text}
                                    tooltip={labels.dateOfBirth.tooltip}
                                    name={'dateOfBirth'}
                                    testId={'date-of-birth'}
                                    onFocus={() => setLastTouchedField(labels.dateOfBirth.text)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={
                                        <>
                                            {labels.confirmTermsAndConditions.text}{' '}
                                            <a href={legalNoticePath()} target="_blank" rel="noopener noreferrer">
                                                {labels.confirmTermsAndConditions.link}
                                            </a>
                                            <InfoIcon text={labels.confirmTermsAndConditions.tooltip} />
                                        </>
                                    }
                                    name="confirmTermsAndConditions"
                                    testId={'private-confirm-terms-and-conditions'}
                                    onFocus={() =>
                                        setLastTouchedField(
                                            `${labels.confirmTermsAndConditions.text} ${labels.confirmTermsAndConditions.link}`,
                                        )
                                    }
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={
                                        <>
                                            {labels.confirmPrivacyPolicy.text}{' '}
                                            <a href={privacyPolicyPath()} target="_blank" rel="noopener noreferrer">
                                                {labels.confirmPrivacyPolicy.link}
                                            </a>{' '}
                                            <InfoIcon text={labels.confirmPrivacyPolicy.tooltip} />
                                        </>
                                    }
                                    name="confirmPrivacyPolicy"
                                    testId={'private-confirm-privacy-policy'}
                                    onFocus={() =>
                                        setLastTouchedField(
                                            `${labels.confirmPrivacyPolicy.text} ${labels.confirmPrivacyPolicy.link}`,
                                        )
                                    }
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Button
                                    full={true}
                                    onClick={() => {
                                        formik.submitForm();
                                        if (!isEmpty(formik.errors) || isEmpty(formik.touched)) {
                                            const errorToString = getErrors(formik.errors).toString();
                                            if (!errorToString) {
                                                onAction(initialListErrors);
                                            } else onAction(getErrors(formik.errors));
                                        }
                                    }}
                                    testId={'submit-private-registration-button'}
                                >
                                    {t(`registration-form.button-labels.confirm`)}
                                </Button>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </Layout.Item>
    );
};
