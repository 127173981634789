import React from 'react';
import { useHistory } from 'react-router-dom';
import { CarOwnershipAlert } from 'common';
import { proofOfCarOwnershipPath } from 'components/navigation/paths';
import { AlertNotification } from '../alert-notification';
import { useTranslation } from 'react-i18next';

export type CarOwnershipAlertNotificationProps = {
    encryptedContractNumber?: string;
    carOwnershipAlert?: CarOwnershipAlert;
};

export const CarOwnershipAlertNotification: React.FC<CarOwnershipAlertNotificationProps> = ({
    encryptedContractNumber,
    carOwnershipAlert,
}) => {
    const { t } = useTranslation('proof-of-car-ownership');
    const history = useHistory();

    if ((!carOwnershipAlert?.headline && !carOwnershipAlert?.description) || !encryptedContractNumber) return null;

    const handleClick = () => {
        history.push(proofOfCarOwnershipPath(encryptedContractNumber));
    };

    const { headline, description, docExpected } = carOwnershipAlert;
    return (
        <AlertNotification
            headline={headline}
            text={description}
            buttonLabel={docExpected ? t('alert-notification.button') : ''}
            onClick={handleClick}
            testId={'proof-of-car-ownership-alert'}
        />
    );
};
