import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import './style.css';
import {
    Button,
    CustomRangeSlider,
    Fieldset,
    Form,
    Input,
    Layout,
    SectionHeading,
    Combobox,
} from '@vwfs-bronson/bronson-react';
import { useFormik } from 'formik';
import {
    preventSubmit,
    Spinner,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
    Notification,
    NotificationStatus,
} from '@cp-shared-5/frontend-ui';
import { formatCpDate, trimAllValues } from '@cp-shared-5/common-utilities';
import {
    AcceptPartialEarlySettlementBody,
    formatAsCurrency,
    getPartialEarlySettlementEndpoint,
    PartialEarlySettlementBody,
    PartialEarlySettlementResponse,
    partialEarlySettlementValidationSchema,
    PartialEarlySettlementError,
} from 'common';
import { getInitialValues } from './initialValues';
import { CpDataApi } from 'cp-xhr';

export type OptionCalculatorFormProps = {
    encryptedContractNumber: string;
    setCalculatedOptions: Function;
    setIsOptionSelected: Function;
    setSelectedOption: Function;
    setAcceptRequest: Function;
};

export const OptionCalculatorForm: React.FC<OptionCalculatorFormProps> = ({
    encryptedContractNumber,
    setCalculatedOptions,
    setSelectedOption,
    setIsOptionSelected,
    setAcceptRequest,
}) => {
    const { t } = useTranslation('partial-early-settlement');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isPESLoading, setIsPESLoading] = useState(true);
    const [isPESLoadingError, setIsPESLoadingError] = useState(false);
    const [partialEarlySettlement, setPartialEarlySettlement] = useState<PartialEarlySettlementResponse>();
    const [chosenSettlementDate, setChosenSettlementDate] = useState<string>();
    const [roundedOutstandingCapital, setRoundedOutstandingCapital] = useState<number>();
    const [roundedMinPartialSettlement, setRoundedMinPartialSettlement] = useState<number>(1);
    const [defaultPartialSettlementAmount, setDefaultPartialSettlementAmount] = useState<number>();
    const [calculatingPartialEarlySettlementError, setCalculatingPartialEarlySettlementError] =
        useState<boolean>(false);
    const [inputAmount, setInputAmount] = useState<number>();
    const [sliderAmount, setSliderAmount] = useState<number>();

    useAnalyticsPageViewTracker('earlySettlementSimulation');
    const { onAction: onCalculate } = useAnalyticsActionTracker('onEarlySettlementCalculate');

    const onSubmit = (): void => {
        if (inputAmount && chosenSettlementDate) {
            onCalculate();

            setIsSubmitting(true);

            const requestBody: PartialEarlySettlementBody = trimAllValues({
                settlementDate: chosenSettlementDate,
                partialEarlySettlement: inputAmount,
            });

            CpDataApi.post(getPartialEarlySettlementEndpoint(encryptedContractNumber), requestBody)
                .then((response) => {
                    if (!response.data?.contractAfterReduceRate && !response.data?.contractAfterReduceEndDate) {
                        throw new Error();
                    } else {
                        setCalculatedOptions(response.data);
                        setAcceptRequest(requestBody);
                        setCalculatingPartialEarlySettlementError(false);
                    }
                })
                .catch(() => {
                    setCalculatedOptions(undefined);
                    setAcceptRequest(undefined);
                    setSelectedOption(undefined);
                    setCalculatingPartialEarlySettlementError(true);
                    setIsOptionSelected(false);
                })
                .finally(() => setIsSubmitting(false));
        }
    };

    const validationErrors: PartialEarlySettlementError = {
        settlementDate: {
            required: t('validation.empty'),
            valid: t('validation.valid-date'),
            min: t('validation.future-date'),
        },
    };

    const formik = useFormik({
        initialValues: getInitialValues(),
        onSubmit: onSubmit,
        validationSchema: partialEarlySettlementValidationSchema(validationErrors),
    });

    useEffect(() => {
        setIsPESLoading(true);
        CpDataApi.get(getPartialEarlySettlementEndpoint(encryptedContractNumber))
            .then((response) => {
                setRoundedOutstandingCapital(Math.round(response.data.maxPartialEarlySettlement));
                setRoundedMinPartialSettlement(Math.round(response.data.minPartialEarlySettlement));
                setDefaultPartialSettlementAmount(Math.round(response.data.maxPartialEarlySettlement / 2));
                setPartialEarlySettlement(response.data);
                if (response.data.settlementDate.length === 1) {
                    setChosenSettlementDate(response.data.settlementDate[0]);
                    setAcceptRequest((state: AcceptPartialEarlySettlementBody) => ({
                        ...state,
                        settlementDate: response.data.settlementDate[0],
                    }));
                }
            })
            .catch((_) => {
                setIsPESLoadingError(true);
            })
            .finally(() => {
                setIsPESLoading(false);
            });
    }, [encryptedContractNumber, setAcceptRequest]);

    useEffect(() => {
        if (partialEarlySettlement?.settlementDate?.length === 1)
            (async () => {
                await formik.setFieldValue('settlementDate', chosenSettlementDate);
            })();
        // eslint-disable-next-line
    }, [chosenSettlementDate, partialEarlySettlement]);

    useEffect(() => {
        setInputAmount(defaultPartialSettlementAmount);
        setSliderAmount(defaultPartialSettlementAmount);
    }, [defaultPartialSettlementAmount]);

    const handleChangeForInputAmount = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const intValue = parseInt(event.target.value);
        if (!intValue) {
            setInputAmount(defaultPartialSettlementAmount);
            setSliderAmount(defaultPartialSettlementAmount);
        } else {
            setInputAmount(intValue);
            setSliderAmount(intValue);
        }
    };

    const handleChangeForSliderValue = (value: string): void => {
        setInputAmount(Math.round(parseFloat(value)));
    };

    useEffect(() => {
        formik.setFieldValue('settlementDate', chosenSettlementDate);
        // eslint-disable-next-line
    }, [chosenSettlementDate]);

    return (
        <>
            {isSubmitting && <Spinner fullPage={true} />}

            <Layout.Item className="u-mt" default="1/1" s="1/1">
                <SectionHeading level="3" subtitle={t('calculate.text')}>
                    {t('calculate.headline')}
                </SectionHeading>
            </Layout.Item>
            {!isPESLoadingError ? (
                <Layout center>
                    <Layout.Item middle className="u-text-center" center={true} default="3/4" s="1/1">
                        <SectionHeading center={true} level="5">
                            {t('calculate.sub-heading')}
                        </SectionHeading>

                        <Form onSubmit={preventSubmit()} data-testid="optionCalculatorForm">
                            <Fieldset className="u-text-center">
                                {!isPESLoading && sliderAmount && inputAmount ? (
                                    <>
                                        <Fieldset.Row>
                                            <Layout.Item className="u-text-center" default="1/4" s="1/1">
                                                <Input
                                                    testId="inputPartialSettlementAmount"
                                                    type="text"
                                                    addonText="€"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                                        handleChangeForInputAmount(event);
                                                    }}
                                                    value={inputAmount}
                                                />
                                            </Layout.Item>
                                        </Fieldset.Row>
                                        <Fieldset.Row>
                                            <Layout.Item default="1/1" s="1/1">
                                                <CustomRangeSlider
                                                    testId="sliderPartialSettlementAmount"
                                                    value={sliderAmount}
                                                    range={{
                                                        min: roundedMinPartialSettlement,
                                                        max: roundedOutstandingCapital,
                                                    }}
                                                    pips={{
                                                        mode: 'steps',
                                                        density: roundedOutstandingCapital,
                                                        format: {
                                                            to: (value: string): string => {
                                                                const intValue = parseInt(value);
                                                                if (intValue > roundedMinPartialSettlement) {
                                                                    return `max. ${formatAsCurrency(parseInt(value))}`;
                                                                }
                                                                return `min. ${formatAsCurrency(parseInt(value))}`;
                                                            },
                                                        },
                                                    }}
                                                    tooltips={{
                                                        to: (value: string): string =>
                                                            formatAsCurrency(Math.round(parseFloat(value))),
                                                    }}
                                                    onChange={(value: string): void =>
                                                        handleChangeForSliderValue(value)
                                                    }
                                                />
                                            </Layout.Item>
                                        </Fieldset.Row>
                                    </>
                                ) : (
                                    <Spinner center />
                                )}
                                <Fieldset.Row className="u-mt-large">
                                    <Layout.Item className="u-text-left" default="1/2" s="1/1">
                                        {partialEarlySettlement?.settlementDate &&
                                        partialEarlySettlement?.settlementDate?.length > 1 ? (
                                            <>
                                                <Combobox
                                                    testId={'settlementDateDropdown'}
                                                    icon="semantic-expand"
                                                    name="settlementDate"
                                                    label=""
                                                    onChange={(date: string): void => {
                                                        setChosenSettlementDate(date);
                                                        setAcceptRequest((state: AcceptPartialEarlySettlementBody) => ({
                                                            ...state,
                                                            settlementDate: date,
                                                        }));
                                                    }}
                                                >
                                                    {partialEarlySettlement?.settlementDate.map((date, index) => (
                                                        <Combobox.Item
                                                            testId={`settlementDate-item-${index}`}
                                                            optionValue={date}
                                                            optionKey={date}
                                                            key={index}
                                                        >
                                                            <span>{formatCpDate(date).format('DD.MM.YYYY')}</span>
                                                        </Combobox.Item>
                                                    ))}
                                                </Combobox>
                                                {formik.touched.settlementDate && (
                                                    <span className="c-error-message">
                                                        {formik.errors.settlementDate}
                                                    </span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Input
                                                    testId="dateSettlementInput"
                                                    type="text"
                                                    disabled={true}
                                                    name="settlementDate"
                                                    value={formatCpDate(
                                                        partialEarlySettlement?.settlementDate?.[0],
                                                    ).format('DD-MM-YYYY')}
                                                />
                                                <span className="c-error-message">{formik.errors.settlementDate}</span>
                                            </>
                                        )}

                                        <p className="u-text-muted u-text-fs-1">{t('calculate.date-hint')}</p>
                                    </Layout.Item>
                                    <Layout.Item className="u-text-center" default="1/2" s="1/1">
                                        <Button
                                            onClick={formik.submitForm}
                                            testId="calculateButton"
                                            type="submit"
                                            full
                                            disabled={isSubmitting}
                                        >
                                            {t('calculate.calculate-button')}
                                        </Button>
                                    </Layout.Item>
                                    {calculatingPartialEarlySettlementError && (
                                        <Notification
                                            className="u-text-left u-mt"
                                            status={NotificationStatus.error}
                                            text={t('calculate.errors.no-calculations')}
                                        />
                                    )}
                                </Fieldset.Row>
                            </Fieldset>
                        </Form>
                    </Layout.Item>
                </Layout>
            ) : (
                <Notification
                    className="u-text-left u-mt-large"
                    status={NotificationStatus.error}
                    text={t('calculate.errors.be-not-available')}
                />
            )}
        </>
    );
};
