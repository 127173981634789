import React from 'react';
import { useTranslation } from 'react-i18next';
import { DefinitionListItem, withAutoScroll } from '@cp-shared-5/frontend-ui';
import { ManageInsurancePoliciesFormValues } from '../initailValues';
import { ConfirmationView } from '../../confirmation-view';
import { EditStatus } from 'common';

type ConfirmationViewProps = {
    formValues: ManageInsurancePoliciesFormValues;
    onConfirm: () => void;
    onClose: () => void;
    lastEditStatus: EditStatus;
};

export const SummaryView: React.FC<ConfirmationViewProps> = withAutoScroll(
    ({ formValues, onConfirm, onClose, lastEditStatus }) => {
        const { t } = useTranslation('manage-insurance-policies');

        const { insurerName, policyNumber, insuredCarPrice } = formValues;

        const confirmationList: DefinitionListItem[] = [
            {
                label: t('form-view.insurer.label'),
                value: insurerName,
                testId: 'insurer',
            },
            {
                label: t('form-view.policy-number.label'),
                value: policyNumber,
                testId: 'policyNumber',
            },
            {
                label: t('form-view.insured-car-price.label'),
                value: `${insuredCarPrice} ${t('translation:currency')}`,
                testId: 'insuredCarPrice',
            },
        ];

        const isSuccess = lastEditStatus === EditStatus.SUCCESS;

        return (
            <ConfirmationView
                items={confirmationList}
                onConfirm={onConfirm}
                onCancel={onClose}
                subHeadline={t('confirmation.title')}
                isSuccess={isSuccess}
            />
        );
    },
    'ManageInsurancePoliciesSummaryView',
);
