import {
    AddressType,
    BaseAddressValidationError,
    CustomerType,
    FiscalAddressValidationError,
    PostalAddressValidationError,
    UpdateFiscalAddress,
    updateFiscalAddressValidator,
    UpdatePostalAddress,
    updatePostalAddressValidator,
} from 'common';
import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { IdCardError } from 'common';
import { areAllFilesValid } from '../../../../file-upload/helpers';

const commonTranslationPrefix = 'edit-view.common';
const fiscalTranslationPrefix = 'edit-view.fiscal';
const postalTranslationPrefix = 'edit-view.postal';

type PostalAddressPrivateForm = Omit<UpdatePostalAddress, 'files'> & {
    backOfDocument: File[];
    frontOfDocument: File[];
};
type PostalAddressCompanyForm = Omit<UpdatePostalAddress, 'files' | 'personIdCardData'> & {
    registryDocument: File[];
};

const fiscalAddressValidator = (
    t: TFunction,
    baseErrors: BaseAddressValidationError,
): Yup.SchemaOf<UpdateFiscalAddress> => {
    const errors: FiscalAddressValidationError = {
        ...baseErrors,
        secondLine: { max: t(`${fiscalTranslationPrefix}.second-line.max-length-error`) },
    };
    return updateFiscalAddressValidator(errors).omit(['address.country']);
};

const postalPrivateAddressValidator = (
    t: TFunction,
    baseErrors: BaseAddressValidationError,
    idCardError: IdCardError,
): Yup.SchemaOf<PostalAddressPrivateForm> => {
    const errors: PostalAddressValidationError = {
        ...baseErrors,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        files: undefined,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        personIdCardData: idCardError,
    };

    return updatePostalAddressValidator(errors)
        .omit(['files'])
        .shape({
            backOfDocument: Yup.array()
                .max(1, t(`${postalTranslationPrefix}.document.max-error`))
                .test('files', '', (files) => areAllFilesValid(files as File[])),
            frontOfDocument: Yup.array()
                .min(1, t(`${postalTranslationPrefix}.document.missing-error`))
                .max(1, t(`${postalTranslationPrefix}.document.max-error`))
                .test('files', '', (files) => areAllFilesValid(files as File[])),
        });
};

const postalCompanyAddressValidator = (
    t: TFunction,
    baseErrors: BaseAddressValidationError,
): Yup.SchemaOf<PostalAddressCompanyForm> => {
    const errors: PostalAddressValidationError = {
        ...baseErrors,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        files: undefined,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        personIdCardData: undefined,
    };

    return updatePostalAddressValidator(errors)
        .omit(['files', 'personIdCardData'])
        .shape({
            registryDocument: Yup.array()
                .min(1, t(`${postalTranslationPrefix}.document.missing-error`))
                .max(1, t(`${postalTranslationPrefix}.document.max-error`)),
        });
};

export const validationSchema = (
    addressType: AddressType,
    t: TFunction,
    customerType?: CustomerType,
): Yup.SchemaOf<UpdateFiscalAddress | PostalAddressCompanyForm | PostalAddressPrivateForm> => {
    const baseErrors: BaseAddressValidationError = {
        city: {
            oneOf: t(`${commonTranslationPrefix}.city.invalid-error`),
            required: t(`${commonTranslationPrefix}.city.missing-error`),
        },
        country: { required: t(`${commonTranslationPrefix}.country.error`) },
        postalCode: {
            match: t(`${commonTranslationPrefix}.postal-code.match-error`),
            required: t(`${commonTranslationPrefix}.postal-code.missing-error`),
        },
        street: {
            max: t(`${commonTranslationPrefix}.street.max-length-error`),
            required: t(`${commonTranslationPrefix}.street.missing-error`),
        },
    };

    const idCardError: IdCardError = {
        issueCity: {
            required: t(`${postalTranslationPrefix}.city-of-issue.error`),
        },
        issueDate: {
            required: t(`${postalTranslationPrefix}.date-of-issue.error`),
            valid: t(`${postalTranslationPrefix}.date-of-issue.error`),
        },
        number: {
            matches: t(`${postalTranslationPrefix}.id-number.error`),
            required: t(`${postalTranslationPrefix}.id-number.error`),
            requiredForPassport: t(`${postalTranslationPrefix}.id-number.error-required-for-passport`),
            unknownType: t(`${postalTranslationPrefix}.id-number.error`),
        },
        type: {
            oneOf: `Card ID type must be one of:`,
            required: t(`${postalTranslationPrefix}.document.missing-error`),
        },
    };

    if (addressType === AddressType.FISCAL) return fiscalAddressValidator(t, baseErrors);
    if (customerType === 'Business') return postalCompanyAddressValidator(t, baseErrors);
    return postalPrivateAddressValidator(t, baseErrors, idCardError);
};
