import React from 'react';
import { Button, Card, Layout, SectionHeading } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { partialEarlySettlementPath, totalEarlySettlementPath, dashboardPagePath } from 'components/navigation/paths';
import { isPartialEarlySettlementAvailable, isTotalEarlySettlementAvailable } from '../total-early-settlement';
import { Contract } from 'common';
import { NoConnectionNotification } from 'components/notifications/no-connection/NoConnectionNotification';

export type EarlySettlementProps = {
    encryptedContractNumber: string;
    contract?: Contract;
};

export const JointEarlySettlementUi: React.FC<EarlySettlementProps> = ({ encryptedContractNumber, contract }) => {
    const { t } = useTranslation('joint-early-settlement');
    const history = useHistory();

    if (!contract) return <NoConnectionNotification testId={'no-connection'} />;

    const cards = [];

    if (isPartialEarlySettlementAvailable(contract)) {
        cards.push({
            testId: 'partialEarlySettlement',
            icon: 'semantic-calculator',
            title: t('partial.title'),
            body: t('partial.body'),
            pagePath: () => history.push(partialEarlySettlementPath(encryptedContractNumber)),
        });
    }

    if (isTotalEarlySettlementAvailable(contract)) {
        cards.push({
            testId: 'totalEarlySettlement',
            icon: 'semantic-checkmark',
            title: t('total.title'),
            body: t('total.body'),
            pagePath: () => history.push(totalEarlySettlementPath(encryptedContractNumber)),
        });
    }

    if (!cards.length) return <NoConnectionNotification testId={'no-connection'} />;

    return (
        <>
            <SectionHeading level="3">{t('subheadline')}</SectionHeading>
            <Layout>
                <Layout.Item default="1/1">
                    <Layout className={'o-layout--equal-height'}>
                        {cards.map((card, index) => (
                            <Layout.Item key={index} default={`1/${cards.length}`} m="1/1">
                                <Card
                                    testId={`${card.testId}Card`}
                                    className="u-mt"
                                    element="article"
                                    icon={card.icon}
                                    title={card.title}
                                    buttons={[
                                        <Button testId={`${card.testId}Button`} key={index} onClick={card.pagePath}>
                                            {t('button.continue')}
                                        </Button>,
                                    ]}
                                    offer
                                >
                                    {card.body}
                                </Card>
                            </Layout.Item>
                        ))}
                    </Layout>
                </Layout.Item>
                <Layout.Item className={'u-text-center'} default="1/1">
                    <Button secondary onClick={(): void => history.push(dashboardPagePath())} testId="backButton">
                        {t('translation:editable-section-nav.back')}
                    </Button>
                </Layout.Item>
            </Layout>
        </>
    );
};
