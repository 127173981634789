export * from './icon-footer';
export * from './legal-footer';
export * from './simple-teaser';
export * from './faq';
export * from './error-page';
export * from './simple-page';
export * from './landing-page-marketing-card';
export * from './landing-page-teaser';
export * from './marketing-card';
export * from './auto-logout-success';
