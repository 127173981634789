import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { manageInsurancePoliciesPath } from 'components/navigation/paths';
import { AlertNotification } from '../alert-notification';
import { ManageInsurancePoliciesAlert } from 'common';

export type ManageInsurancePoliciesAlertNotificationProps = {
    encryptedContractNumber?: string;
    hpAlert?: ManageInsurancePoliciesAlert[];
};

export const ManageInsurancePoliciesAlertNotification: React.FC<ManageInsurancePoliciesAlertNotificationProps> = ({
    encryptedContractNumber,
    hpAlert,
}) => {
    const { t } = useTranslation('manage-insurance-policies');
    const history = useHistory();

    if (!hpAlert?.length || !encryptedContractNumber) return null;

    const handleClick = () => {
        history.push(manageInsurancePoliciesPath(encryptedContractNumber));
    };

    return (
        <>
            {hpAlert?.map(({ headline, description, docExpected }, index) => {
                if (!!headline && !!description) {
                    return (
                        <AlertNotification
                            key={index}
                            headline={headline}
                            text={description}
                            buttonLabel={docExpected ? t('notification.button') : ''}
                            onClick={handleClick}
                            testId={'manage-insurance-policies-alert'}
                        />
                    );
                }
            })}
        </>
    );
};
