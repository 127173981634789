import { SimplePage, getCookiePolicyEndpoint } from 'common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimplePage>({
    contentName: 'cookiePolicy',
    contentEndpoint: getCookiePolicyEndpoint,
});

export default reducer;
export const fetchCookiePolicy = fetchContent;
