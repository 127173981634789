import * as Yup from 'yup';
import { areAllFilesValid } from '../file-upload/helpers';
import { ProofOfInsuranceFe, ProofOfInsuranceFeError, proofOfInsuranceValidatorFE } from 'common';
import { getInsurerId, transformCurrencyToNumber } from './utils';
import Insurers from './insurer-selection/isurers.json';

export const getValidationSchema = (errors: ProofOfInsuranceFeError): Yup.SchemaOf<ProofOfInsuranceFe> =>
    proofOfInsuranceValidatorFE(errors).concat(
        Yup.object().shape({
            insurerName: Yup.string()
                .trim()
                .required(errors.insurerName.required)
                .test('existingId', errors.insurerName.required, (insurerName?: string): boolean =>
                    insurerName ? getInsurerId(Insurers, insurerName) > 0 : false,
                ),
            insuredCarPrice: Yup.string()
                .trim()
                .test('format', errors.insuredCarPrice.required, (currency?: string): boolean =>
                    currency ? transformCurrencyToNumber(currency) !== 0 : false,
                )
                .required(errors.insuredCarPrice.required),
            files: Yup.array()
                .min(1, errors.files.min)
                .max(1, errors.files.max)
                .test('files', '', (files) => areAllFilesValid(files as File[])),
        }),
    );
