import { Button, ButtonContainer, Layout } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { Notification, NotificationStatus, Spinner } from '@cp-shared-5/frontend-ui';
import { useHistory } from 'react-router';
import { Contract, getEarlySettlementEndpoint } from 'common';
import { dashboardPagePath, myProfilePagePath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';

type EarlySettlementAllowedProps = {
    contract: Contract;
};

export const EarlySettlementAllowed: React.FC<EarlySettlementAllowedProps> = ({ contract }) => {
    const { t } = useTranslationWithFormatting('total-early-settlement');
    const [isProcessing, setIsProcessing] = useState(false);
    const [status, setStatus] = useState<'Success' | 'Error'>();
    const history = useHistory();

    const onSubmit = (): void => {
        setIsProcessing(true);
        CpDataApi.post(getEarlySettlementEndpoint(contract?.encryptedContractNumber || ''))
            .then(() => setStatus('Success'))
            .catch(() => setStatus('Error'))
            .finally((): void => {
                setIsProcessing(false);
            });
    };

    const backButtonComponent = (
        <Button secondary onClick={(): void => history.push(dashboardPagePath())} testId="back-button" type="btn">
            {t('allowed.buttons.back')}
        </Button>
    );

    return (
        <Layout>
            <Layout.Item default="1/1">
                <h4>{t('allowed.headline')}</h4>
                <p>{t('allowed.description')}</p>
                <p>
                    <span>{t('allowed.my-profile-text')}</span>
                    <a href={myProfilePagePath()}>{t('allowed.my-profile-link')}</a>
                </p>
            </Layout.Item>
            {status && (
                <>
                    <Layout.Item default="1/1">
                        {status === 'Success' && (
                            <Notification
                                status={NotificationStatus.success}
                                testId={'notification-success'}
                                text={t('allowed.messages.success')}
                            />
                        )}
                        {status === 'Error' && (
                            <Notification
                                status={NotificationStatus.error}
                                testId={'notification-error'}
                                text={t('allowed.messages.error')}
                            />
                        )}
                    </Layout.Item>
                    <Layout.Item default="1/1">
                        <ButtonContainer center>{backButtonComponent}</ButtonContainer>
                    </Layout.Item>
                </>
            )}
            {!status && (
                <Layout.Item default="1/1">
                    {isProcessing ? (
                        <Spinner center />
                    ) : (
                        <ButtonContainer center>
                            {backButtonComponent}
                            <Button onClick={onSubmit} testId="request-button" type="btn">
                                {t('allowed.buttons.request')}
                            </Button>
                        </ButtonContainer>
                    )}
                </Layout.Item>
            )}
        </Layout>
    );
};
