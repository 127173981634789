import { EndpointMockDescription, MockOptions } from '@cp-shared-5/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { ContractsWithAllValues } from '../ExampleData';
import { getContractsEndpoint } from 'common';

const contractsMockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: ContractsWithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};
export const ContractsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Contract Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: contractsMockOptions,
    url: getContractsEndpoint(),
};
