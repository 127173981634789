import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContractStatus, formatAsCurrency, formatAsDate } from 'common';
import { ContractSummary, ContractSummaryItemProps } from '@cp-shared-5/frontend-ui';
import { CPDate } from '@cp-shared-5/common-utilities';
import { getInactiveContractSummary } from '../utils';

export type BallonCreditProps = {
    isActive: boolean;
    nextPaymentDate?: CPDate;
    nextPaymentAmount?: number;
    outstandingBalance?: number;
    status?: ContractStatus;
    startDate?: CPDate;
    endDate?: CPDate;
};

export const BallonCredit: React.FC<BallonCreditProps> = ({
    isActive,
    nextPaymentDate,
    nextPaymentAmount,
    status,
    startDate,
    endDate,
}) => {
    const { t } = useTranslation('financial-contract-header');

    const paymentDateValue = nextPaymentDate ? formatAsDate(nextPaymentDate) : '-';
    const nextPaymentAmountValue = nextPaymentAmount ? formatAsCurrency(nextPaymentAmount) : '-';

    const activeItems: Array<ContractSummaryItemProps> = [
        { value: paymentDateValue || '-', label: t('next-payment-date'), testClass: 'next-payment-date' },
        {
            value: nextPaymentAmountValue,
            label: t('next-payment-amount'),
            testClass: 'next-payment-amount',
        },
    ];

    return (
        <ContractSummary items={isActive ? activeItems : getInactiveContractSummary(t, status, startDate, endDate)} />
    );
};
