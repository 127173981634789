import { EarlySettlement } from 'common';
import { formatCpDate } from '@cp-shared-5/common-utilities';

export const EarlySettlementAllowedData: EarlySettlement = {
    earlySettlementStatusInfo: {
        status: 'Allowed',
        statusHeadline: 'Allowed Headline',
        statusDescription: 'Allowed Description',
    },
    summary: {
        nextDueAmount: 1500,
        pendingInstalments: 1000,
        nextDueDate: formatCpDate('2024-10-11T09:44:57.299').toCpDate(),
        expectedContractEndDate: formatCpDate('2025-10-11T09:44:57.299').toCpDate(),
        pendingCapital: 500,
    },
};

export const EarlySettlementActiveData: EarlySettlement = {
    earlySettlementStatusInfo: {
        status: 'Active',
        statusHeadline: 'Active Headline',
        statusDescription: 'Active Description',
    },
    summary: {
        nextDueAmount: 1500,
        pendingInstalments: 1000,
        nextDueDate: formatCpDate('2024-10-11T09:44:57.299').toCpDate(),
        expectedContractEndDate: formatCpDate('2025-10-11T09:44:57.299').toCpDate(),
        pendingCapital: 500,
    },
    details: {
        instalmentsToSettle: 10,
        outstandingCapital: 20,
        unpaidOverpaidBalanceUpToDate: 30,
        administrationFee: 40,
        accuredInterestAmountToBePaid: 50,
        nextInsurancePaymentDue: 60,
        outstandingInsurancePremium: 70,
        interestOnTheLatePayments: 80,
        totalSettlementAmount: 90,
        validityPeriodOfTheQuotation: formatCpDate('2025-10-11T09:44:57.299').toCpDate(),
    },
};

export const EarlySettlementForbiddenData: EarlySettlement = {
    earlySettlementStatusInfo: {
        status: 'Forbidden',
        statusHeadline: 'Forbidden Headline',
        statusDescription: 'Forbidden Description',
    },
    summary: {
        nextDueAmount: 1500,
        pendingInstalments: 1000,
        nextDueDate: formatCpDate('2024-10-11T09:44:57.299').toCpDate(),
        expectedContractEndDate: formatCpDate('2025-10-11T09:44:57.299').toCpDate(),
        pendingCapital: 500,
    },
};

export const EarlySettlementCalculatingData: EarlySettlement = {
    earlySettlementStatusInfo: {
        status: 'Requested',
        statusHeadline: 'Requested Headline',
        statusDescription: 'Requested Description',
    },
    summary: {
        nextDueAmount: 1500,
        pendingInstalments: 1000,
        nextDueDate: formatCpDate('2024-10-11T09:44:57.299').toCpDate(),
        expectedContractEndDate: formatCpDate('2025-10-11T09:44:57.299').toCpDate(),
        pendingCapital: 500,
    },
};

export const EarlySettlementExpiredData: EarlySettlement = {
    earlySettlementStatusInfo: {
        status: 'Expired',
        statusHeadline: 'Expired Headline',
        statusDescription: 'Expired Description',
    },
    summary: {
        nextDueAmount: 1500,
        pendingInstalments: 1000,
        nextDueDate: formatCpDate('2024-10-11T09:44:57.299').toCpDate(),
        expectedContractEndDate: formatCpDate('2025-10-11T09:44:57.299').toCpDate(),
        pendingCapital: 500,
    },
};
