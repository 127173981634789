import { CPDate } from '@cp-shared-5/common-utilities';

export type PersonRegistrationData = {
    customerNumber?: number;
    firstName?: string;
    surname?: string;
    dateOfBirth?: CPDate;
};

export type BusinessRegistrationData = {
    customerNumber?: number;
    companyName?: string;
    companyIdentificationNumber?: string;
};

export type RegistrationResult = {
    phoneNumberEnding?: string;
    customerId?: string;
};

export interface UserRegistrationResult {
    /*
     * An identifier whether user is registered in CustomerPortal or not
     */
    isRegistered: boolean;
}

export enum RegistrationError {
    CUSTOMER_NUMBER_NOT_FOUND = 'CUSTOMER_NUMBER_NOT_FOUND',
    NOT_FOUND_BY_NAME = 'NOT_FOUND_BY_NAME',
    NOT_FOUND_BY_COMPANY_NAME = 'NOT_FOUND_BY_COMPANY_NAME',
    ALREADY_REGISTERED = 'ALREADY_REGISTERED',
    PHONE_NUMBER_NOT_FOUND = 'PHONE_NUMBER_NOT_FOUND',
    IS_LOCKED_OUT = 'IS_LOCKED_OUT',
    INCORRECT_CUSTOMER_TYPE = 'INCORRECT_CUSTOMER_TYPE',
}

export type RegistrationSmsVerify = {
    smsToken?: string;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type RegistrationSmsVerifyResult = {};

export enum RegistrationSmsVerifyError {
    WRONG_SMS_TOKEN = 'WRONG_SMS_TOKEN',
    EXPIRED_SMS_TOKEN = 'EXPIRED_SMS_TOKEN',
    IS_LOCKED_OUT = 'IS_LOCKED_OUT',
}
