import * as Yup from 'yup';
import { ProofOfCarOwnership } from '../../apis';

import { fileInfoValidator } from './FileInfoValidator';
import { defaultProofOfCarOwnershipError } from '../messages';
import { ProofOfCarOwnershipError } from '../types';
import { formatCpDate } from '@cp-shared-5/common-utilities';
import { licenseValidator, technicalDocumentNumberValidator } from './LicensePlateValidator';
import { DATE_FORMAT } from '../../formatters';

export const proofOfCarOwnershipValidator = (
    errors: Omit<ProofOfCarOwnershipError, 'files'> = defaultProofOfCarOwnershipError,
): Yup.SchemaOf<Omit<ProofOfCarOwnership, 'files'>> =>
    Yup.object()
        .shape({
            licensePlate: licenseValidator(errors.licensePlate),
            numberOfTechnicalDocument: technicalDocumentNumberValidator(errors.numberOfTechnicalDocument),
            dateOfFirstRegistrationInSk: Yup.string()
                .trim()
                .required(errors.dateOfFirstRegistrationInSk.required)
                .test(
                    'valid',
                    errors.dateOfFirstRegistrationInSk.valid,
                    (value: string | undefined): boolean =>
                        formatCpDate(value).isValid() || formatCpDate(value, DATE_FORMAT).isValid(),
                ),
            yearOfProduction: Yup.string().trim().required(errors.yearOfProduction.required),
        })
        .defined();

export const ProofOfCarOwnershipValidatorBE = (
    errors: ProofOfCarOwnershipError = defaultProofOfCarOwnershipError,
): Yup.SchemaOf<ProofOfCarOwnership> =>
    proofOfCarOwnershipValidator(errors).shape({
        files: Yup.array()
            .of(fileInfoValidator(errors.files.file))
            .required(errors.files.required)
            .min(1, errors.files.min)
            .max(2, errors.files.max),
    });
