import React from 'react';
import { useTranslation } from 'react-i18next';
import { IdCardType } from 'common';
import { ValidatedRadioButtonGroup } from '@cp-shared-5/frontend-ui';

export type IdCardTypeSelectionProps = {
    name: string;
    label: string;
    testId: string;
};

export const IdCardTypeSelection: React.FC<IdCardTypeSelectionProps> = ({ name, label, testId }) => {
    const { t } = useTranslation('id-card-type');

    return (
        <ValidatedRadioButtonGroup
            label={label}
            name={name}
            testId={testId}
            isMandatory
            radioButtons={[
                {
                    label: t('national-id'),
                    value: IdCardType.NATIONAL_ID,
                },
                {
                    label: t('passport'),
                    value: IdCardType.PASSPORT,
                },
                {
                    label: t('residence-card'),
                    value: IdCardType.RESIDENCE_CARD,
                },
            ]}
        />
    );
};
