import React from 'react';

import { SimplePage } from 'common';
import { HeroImage } from '@cp-shared-5/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';

export const LegalNoticeUi: React.FC<{ legalNotice?: SimplePage }> = ({ legalNotice }) => {
    if (!legalNotice) {
        return null;
    }
    const { teaser, content } = legalNotice;

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={false} />
    ) : null;

    const title = content?.title ? <h3>{content.title}</h3> : null;

    return (
        <>
            {teaserComponent}
            <ContentSection pageWrapSize="medium">
                {title}
                <div dangerouslySetInnerHTML={{ __html: content.text }} />
            </ContentSection>
        </>
    );
};
