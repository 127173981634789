import { BundledProducts } from 'common';
import { createGetContractBasedDataFetchSlice } from '@cp-shared-5/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';

const { reducer, fetchData } = createGetContractBasedDataFetchSlice<BundledProducts, DefaultBusinessMarketApiErrorCode>(
    {
        dataName: 'bundledProducts',
        fetchCallback(link: string) {
            return CpDataApi.get(link).then((response) => response.data);
        },
    },
);

export default reducer;
export const fetchBundledProducts = fetchData;
