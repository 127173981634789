import React, { useState } from 'react';
import {
    Card,
    Layout,
    Button,
    ButtonContainer,
    SectionHeading,
    LicensePlate as LicensePlateShared,
} from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import {
    ContractWithLinks,
    PartialEarlySettlementDetails,
    getAcceptPartialEarlySettlementEndpoint,
    AcceptPartialEarlySettlementBody,
} from 'common';
import { ComparisonSection } from './comparison-section';
import { CalculationDetails } from './calculation-details';
import { ContractHeader, Spinner, Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';
import { CpDataApi } from 'cp-xhr';

type QuoteDetailsProps = {
    contractDetails: ContractWithLinks;
    encryptedContractNumber: string;
    continueToOfflinePayment: () => void;
    back: () => void;
    partialEarlySettlementDetails?: PartialEarlySettlementDetails;
    acceptRequest?: AcceptPartialEarlySettlementBody;
};

export const QuoteDetails: React.FC<QuoteDetailsProps> = ({
    contractDetails,
    partialEarlySettlementDetails,
    encryptedContractNumber,
    acceptRequest,
    continueToOfflinePayment,
    back,
}) => {
    const { t } = useTranslation('partial-early-settlement');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [acceptPartialEarlySettlementError, setAcceptPartialEarlySettlementError] = useState(false);

    if (!partialEarlySettlementDetails) return null;

    const LicensePlate = contractDetails?.licensePlate ? (
        <LicensePlateShared
            countryCode={t('country-code')}
            size={'small'}
            registrationNumber={contractDetails?.licensePlate}
            horizontalStripEu={true}
            euStars={true}
        />
    ) : null;

    const acceptPayment = async () => {
        setIsSubmitting(true);

        CpDataApi.post(getAcceptPartialEarlySettlementEndpoint(encryptedContractNumber), acceptRequest)
            .then(() => {
                continueToOfflinePayment();
            })
            .catch(() => {
                setAcceptPartialEarlySettlementError(true);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <>
            {isSubmitting && <Spinner fullPage />}
            <Layout.Item default="1/1">
                <Card element="article">
                    <ContractHeader
                        carInformation={contractDetails.brandModelType!}
                        contractCategory={contractDetails.productName}
                        contractNumber={contractDetails.contractNumber!}
                        contractIdentifier={LicensePlate}
                    />
                </Card>
            </Layout.Item>
            <Layout.Item default="1/1">
                <SectionHeading className="u-mt" level="3">
                    {t('quote-details.headline')}
                </SectionHeading>
                <ComparisonSection partialEarlySettlementDetails={partialEarlySettlementDetails} />
            </Layout.Item>
            <Layout.Item default="1/1">
                <CalculationDetails
                    partialEarlySettlementDetails={partialEarlySettlementDetails}
                    requestDate={acceptRequest?.settlementDate}
                />
            </Layout.Item>
            <Layout.Item default="1/1" className="u-mt">
                <Notification
                    status={NotificationStatus.warning}
                    headline={t('quote-details.warning.headline')}
                    text={t('quote-details.warning.text')}
                    testId={'quoteDetailsNotification'}
                ></Notification>
            </Layout.Item>
            {acceptPartialEarlySettlementError && (
                <Layout.Item default="1/1">
                    <Notification
                        className="u-text-left u-mt-large"
                        status={NotificationStatus.error}
                        text={t('quote-details.errors.be-not-available')}
                    />
                </Layout.Item>
            )}
            <Layout.Item default="1/1">
                <ButtonContainer center>
                    <Button secondary onClick={back} testId="quoteDetailsBackButton">
                        {t('quote-details.back-button')}
                    </Button>
                    <Button onClick={async () => await acceptPayment()} testId="quoteDetailsProceedButton">
                        {t('quote-details.continue-button')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </>
    );
};
