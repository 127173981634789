import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';
import { Contract } from 'common';
import { paymentDetailsPath } from 'components/navigation/paths';

export type UnpaidAlertNotificationProps = {
    contract: Contract;
    testId?: string;
};

export const UnpaidAlertNotification: React.FC<UnpaidAlertNotificationProps> = ({ contract, testId }) => {
    const { t } = useTranslation('contracts');
    const history = useHistory();

    if (!contract.unpaidAlert?.headline && !contract.unpaidAlert?.description) return null;

    const handleClick = () => history.push(paymentDetailsPath(contract.encryptedContractNumber));

    return (
        <Notification
            status={NotificationStatus.warning}
            headline={contract.unpaidAlert?.headline}
            text={contract.unpaidAlert?.description}
            testId={testId || 'unpaid-alert'}
            className={'u-mb-small'}
        >
            {!!contract.unpaidAlert?.detailsAvailable && (
                <Button
                    secondary
                    className="u-bg-white u-mt"
                    testId={testId ? `${testId}-button` : 'unpaid-alert-button'}
                    onClick={handleClick}
                >
                    {t('unpaid-alert-button-text')}
                </Button>
            )}
        </Notification>
    );
};
