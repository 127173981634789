import React from 'react';
import { useContractDetails } from './useContractDetails';
import { ContractDetailsUi } from './ui/ContractDetailsUi';
import { Contract } from 'common';

export const ContractDetails: React.FC<{ contract: Contract }> = ({ contract }) => {
    const { data: contractDetails, isLoading, loadingError } = useContractDetails(contract.contractNumber || '');

    return (
        <ContractDetailsUi
            isLoading={!!isLoading}
            contract={contract}
            contractDetails={contractDetails}
            hasError={!!loadingError}
        />
    );
};
