import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import base64ToBlob from 'b64-to-blob';
import { Button, ButtonContainer, Heading, Card, Layout } from '@vwfs-bronson/bronson-react';
import { dashboardPagePath } from '../navigation/paths';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { PayBySquareImage } from 'common';
import { NoConnectionNotification } from '../notifications/no-connection/NoConnectionNotification';

export type PaymentPageProps = {
    manualBankTransferList: DefinitionListItem[];
    payBySquareList: DefinitionListItem[];
    handleBackToPreviousView: Function;
    headline?: string;
    description?: string;
    payBySquareImage?: PayBySquareImage;
    isError?: boolean;
};

export const PaymentPage: React.FC<PaymentPageProps> = ({
    manualBankTransferList,
    payBySquareList,
    handleBackToPreviousView,
    headline,
    description,
    payBySquareImage,
    isError,
}) => {
    const { t } = useTranslation('payment-page');
    const history = useHistory();
    const [imageUrl, setImageUrl] = useState<string>('');

    const getImageUrl = useCallback(() => {
        if (payBySquareImage && payBySquareImage.image) {
            const imageBlob = base64ToBlob(payBySquareImage.image, payBySquareImage.format);
            setImageUrl(URL.createObjectURL(imageBlob));
        }
    }, [payBySquareImage]);

    useEffect(() => {
        getImageUrl();
    }, [getImageUrl]);

    const backButtonComponent = (
        <Button secondary testId={'back-button'} type="btn" onClick={handleBackToPreviousView}>
            {t('button.back')}
        </Button>
    );

    return isError ? (
        <div className={'u-mt'}>
            <NoConnectionNotification testId={'no-connection'} />
            <div className={'u-text-center u-mt'}>{backButtonComponent}</div>
        </div>
    ) : (
        <div className={'u-mt'}>
            <Heading level={5}>{headline}</Heading>
            <p className={'u-mb'}>{description}</p>
            <Layout equalHeight className={'u-mb-large'}>
                {imageUrl && (
                    <Layout.Item default={'1/2'} m={'1/1'}>
                        <Card className={'u-text-left u-pr-none'} title={t('title.pay-by-square')} element="article">
                            <DefinitionListHorizontal list={payBySquareList} />
                            <div className={'u-text-center'}>
                                <img className={'u-mt-small u-text-center'} src={imageUrl} alt="QR code" />
                            </div>
                        </Card>
                    </Layout.Item>
                )}
                <Layout.Item default={'1/2'} m={'1/1'}>
                    <Card className={'u-text-left u-pr-none'} title={t('title.manual-bank-transfer')} element="article">
                        <DefinitionListHorizontal list={manualBankTransferList} />
                    </Card>
                </Layout.Item>
            </Layout>

            <ButtonContainer center>
                {backButtonComponent}
                <Button
                    secondary
                    testId={'dashboard-button'}
                    type="btn"
                    onClick={(): void => history.push(dashboardPagePath())}
                >
                    {t('button.dashboard')}
                </Button>
            </ButtonContainer>
        </div>
    );
};
