import * as Yup from 'yup';
import { ChangeContractDurationValidatorErrors } from '../types';
import { defaultChangeContractDurationError } from '../messages';

export type ChangeContractDurationFormTypes = {
    newContractDuration: number | undefined;
};

export const changeContractDuarationValidationSchema = (
    currentContractDuration: number,
    errors: ChangeContractDurationValidatorErrors = defaultChangeContractDurationError,
): Yup.SchemaOf<ChangeContractDurationFormTypes> =>
    Yup.object().shape({
        newContractDuration: Yup.number().test(
            'isSameContractDuration',
            errors.newContractDuration.sameAsPrevious,
            (newContractDuration: number | undefined): boolean => {
                return newContractDuration !== currentContractDuration;
            },
        ),
    });
