import React from 'react';
import { Table, ContentSection, Link, Tag } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { ChangeRequestLogEntryWithId, ChangeRequestLogEntryDetails, ChangeStatus, formatAsDate } from 'common';

interface ChangeLogTableProps {
    filteredEntries: ChangeRequestLogEntryWithId[];
    handleShowDetails: (clickedContractDetails: ChangeRequestLogEntryDetails) => void;
}

export const ChangeLogTable: React.FC<ChangeLogTableProps> = ({ filteredEntries, handleShowDetails }) => {
    const { t } = useTranslation('change-log-request');

    const changeStatus: Record<ChangeStatus, JSX.Element> = {
        [ChangeStatus.APPLIED_EN]: <Tag success={true}>{t('change-status.applied')}</Tag>,
        [ChangeStatus.APPLIED_SK]: <Tag success={true}>{t('change-status.applied')}</Tag>,
        [ChangeStatus.PENDING]: <Tag success={false}>{t('change-status.pending')}</Tag>,
        [ChangeStatus.REJECTED]: <Tag error={true}>{t('change-status.rejected')}</Tag>,
        [ChangeStatus.EXPIRED]: <Tag success={false}>{t('change-status.expired')}</Tag>,
    };

    return (
        <ContentSection>
            <Table narrow={true}>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>{t('column.contract-number')}</Table.Th>
                        <Table.Th>{t('column.type-of-request')}</Table.Th>
                        <Table.Th>{t('column.change-status-label')}</Table.Th>
                        <Table.Th>{t('column.date-of-receipt')}</Table.Th>
                        <Table.Th></Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {filteredEntries.map((contract) => (
                        <Table.Tr key={contract.id}>
                            <Table.Td>{contract.contractNumber || t('missing-contract-id')}</Table.Td>
                            <Table.Td>{contract.typeOfRequest}</Table.Td>
                            <Table.Td>{changeStatus[contract.changeStatus]}</Table.Td>
                            <Table.Td>{formatAsDate(contract.dateOfReceipt)}</Table.Td>
                            <Table.Td>
                                <Link
                                    normalLink
                                    icon="semantic-print"
                                    onClick={(): void =>
                                        handleShowDetails({
                                            contractNumber: contract.contractNumber,
                                            dateOfApplication: contract.dateOfApplication,
                                            effectiveDate: contract.effectiveDate,
                                            licensePlate: contract.licensePlate,
                                        })
                                    }
                                >
                                    {t('column.show-details')}
                                </Link>
                            </Table.Td>
                        </Table.Tr>
                    ))}
                </Table.Tbody>
            </Table>
        </ContentSection>
    );
};
