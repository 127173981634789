import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CpDataApi } from '../../../cp-xhr';
import { getInitialValues, RequestInitialValues } from './initialValues';
import { Button, ContentSection, Fieldset, Layout, Modal, Spinner } from '@vwfs-bronson/bronson-react';
import { useHistory } from 'react-router';
import { dashboardPagePath } from '../../navigation/paths';
import { ContractInfo, getRequestSendingEndpoint, Request } from 'common';
import { ContractSelection, generalKey } from './contract-selection/ContractSelection';
import { useAnalyticsActionTracker, ValidatedInput, ValidatedTextarea } from '@cp-shared-5/frontend-ui';
import { messageMaxLength, subjectMaxLength, validationSchema } from './validationSchema';

enum MessageType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    NONE = 'NONE',
}

export type FormViewProps = { contracts?: ContractInfo[] };

export const FormView: React.FC<FormViewProps> = ({ contracts = [] }) => {
    const { t } = useTranslation('request');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [messageType, setMessageType] = useState<MessageType>(MessageType.NONE);
    const history = useHistory();
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');
    const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');

    if (!contracts) {
        return null;
    }

    const handleSubmit = (values: RequestInitialValues, { resetForm }: { resetForm: Function }) => {
        setIsSubmitting(true);
        const { contractSelection, subject: subjectLine = '', message = '' } = values;
        const contractNumber = contractSelection.contractNumber;
        const body: Request = {
            contractNumber: contractNumber === generalKey ? undefined : contractNumber,
            subjectLine,
            message,
        };
        CpDataApi.post(getRequestSendingEndpoint(), body)
            .then(() => {
                onSuccess(`Category: ${subjectLine}`);
                resetForm({ values: getInitialValues() });
                setMessageType(MessageType.SUCCESS);
            })
            .catch(() => {
                onError();
                setMessageType(MessageType.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };
    const handleModalClose = (): void => setMessageType(MessageType.NONE);

    const handleSuccessModalClose = (resetForm: Function) => {
        resetForm({ values: getInitialValues() });
        handleModalClose();
    };

    const handleSuccessModalConfirm = () => {
        handleModalClose();
        history.push(dashboardPagePath());
    };

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={validationSchema(t)}
            onSubmit={handleSubmit}
            validateOnBlur={true}
        >
            {({ values, submitForm, resetForm, dirty, isValid }) => (
                <Form onSubmit={(e) => e.preventDefault()} data-testid="request-form">
                    <ContentSection className={'request-certificate-form'}>
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item>
                                        <ContractSelection
                                            contracts={contracts}
                                            currentContractNumber={values.contractSelection.contractNumber}
                                        />
                                    </Layout.Item>
                                    <Layout.Item>
                                        <ValidatedInput
                                            label={t('subject-label')}
                                            name={'subject'}
                                            inputMode={'text'}
                                            testId={`test-subject`}
                                            maxLength={subjectMaxLength}
                                        />
                                    </Layout.Item>
                                    <Layout.Item>
                                        <ValidatedTextarea
                                            label={t('message-label')}
                                            name={'message'}
                                            testId={`test-message`}
                                            maxLength={messageMaxLength}
                                            enableMaxLength={true}
                                            rows={4}
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <div className="u-text-center">
                                    {isSubmitting ? (
                                        <Spinner center />
                                    ) : (
                                        <Button
                                            testId={'submit-button'}
                                            type="submit"
                                            onClick={() => {
                                                (!dirty || !isValid) && setMessageType(MessageType.VALIDATION_ERROR);
                                                submitForm();
                                            }}
                                        >
                                            {t('translation:editable-section-nav.send')}
                                        </Button>
                                    )}
                                </div>
                            </Fieldset.Row>
                        </Fieldset>
                    </ContentSection>
                    <Modal
                        shown={messageType === MessageType.VALIDATION_ERROR || messageType === MessageType.ERROR}
                        status="error"
                        title={t('modal-error.headline')}
                        onClose={handleModalClose}
                        onCancel={handleModalClose}
                        onClickOutside={handleModalClose}
                        buttonCancelText={t('translation:editable-section-nav.back')}
                        testId={'error-modal'}
                    >
                        {messageType === MessageType.ERROR ? t('modal-error.text') : t('modal-validation-error.text')}
                    </Modal>
                    <Modal
                        shown={messageType === MessageType.SUCCESS}
                        status="success"
                        title={t('modal-success.headline')}
                        onClose={() => handleSuccessModalClose(resetForm)}
                        onConfirm={handleSuccessModalConfirm}
                        onClickOutside={() => handleSuccessModalClose(resetForm)}
                        buttonConfirmText={t('navigation:navigation.dashboard')}
                        testId={'success-modal'}
                    >
                        {t('modal-success.text')}
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
