import React from 'react';
import { useTranslation } from 'react-i18next';
import { DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { ProofOfCarOwnershipFormValues } from '../initialValues';
import { ConfirmationView } from '../../confirmation-view';
import { EditStatus } from 'common';

type ConfirmationViewProps = {
    formValues: ProofOfCarOwnershipFormValues;
    onConfirm: (values: ProofOfCarOwnershipFormValues) => void;
    onClose: () => void;
    lastEditStatus: EditStatus;
};

export const SummaryView: React.FC<ConfirmationViewProps> = ({ formValues, onConfirm, onClose, lastEditStatus }) => {
    const { t } = useTranslation('proof-of-car-ownership');

    const { licensePlate, numberOfTechnicalDocument, yearOfProduction, dateOfFirstRegistrationInSk } = formValues;

    const confirmationList: DefinitionListItem[] = [
        {
            label: t('license-plate.label'),
            value: licensePlate,
            testId: 'license-plate',
        },
        {
            label: t('number-of-technical-document.label'),
            value: numberOfTechnicalDocument,
            testId: 'number-of-technical-document',
        },
        {
            label: t('year-of-production.label'),
            value: yearOfProduction,
            testId: 'year-of-production',
        },
        {
            label: t('date-of-first-registration-in-sk.label'),
            value: dateOfFirstRegistrationInSk,
            testId: 'date-of-first-registration-in-sk',
        },
    ];

    const isSuccess = lastEditStatus === EditStatus.SUCCESS;

    return (
        <ConfirmationView
            items={confirmationList}
            onConfirm={(): void => onConfirm(formValues)}
            onCancel={onClose}
            subHeadline={t('confirmation.title')}
            isSuccess={isSuccess}
        />
    );
};
