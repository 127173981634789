import { ContractInfo } from 'common';

const contractSelectionInitialValues: ContractInfo = {
    isActive: true,
};

export type RequestInitialValues = {
    contractSelection: ContractInfo;
    subject?: string;
    message?: string;
};

export const getInitialValues: () => RequestInitialValues = () => ({
    contractSelection: contractSelectionInitialValues,
    subject: '',
    message: '',
});
