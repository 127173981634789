import React from 'react';
import { ContactDetails, EditStatus } from 'common';
import { ConsultView } from './consult-view/ConsultView';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useUpdateMyProfile } from '../useUpdateMyProfile';
import { changeContactDetailsPath } from '../../navigation/paths';
import { EditView } from './edit-view/EditView';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-5/frontend-ui';

export const ContactSection: React.FC<{ contactDetails?: ContactDetails }> = ({ contactDetails }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { updateMyProfile, myProfileData } = useUpdateMyProfile();

    const { onAction: onStart } = useAnalyticsActionTracker('onEditProfileContact');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');
    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'onEditProfileContactSubmitFailed',
        confirmSuccess: 'onEditProfileContactSuccess',
    });

    const setLastEditStatus = (newEditStatus: EditStatus, updatedContact?: ContactDetails): void => {
        updateMyProfile({
            ...myProfileData,
            contactDetails: updatedContact ?? contactDetails,
            lastEditStatus: { ...myProfileData?.lastEditStatus, contactDetails: newEditStatus },
        });
    };

    const startEditing = (): void => {
        onStart();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changeContactDetailsPath());
    };

    const cancelEditing = (): void => {
        onCancel('Contact');
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(path);
    };

    const finishEditing = (newEditStatus: EditStatus, updatedContact?: ContactDetails): void => {
        newEditStatus === EditStatus.SUCCESS ? onSuccess() : onError();

        setLastEditStatus(newEditStatus, updatedContact);
        history.push(path);
    };

    return (
        <Switch>
            <Route exact path={changeContactDetailsPath()}>
                <EditView contactDetails={contactDetails} cancelEditing={cancelEditing} finishEditing={finishEditing} />
            </Route>
            <Route path={path}>
                <ConsultView
                    contactDetails={contactDetails}
                    startEditing={startEditing}
                    lastEditStatus={myProfileData?.lastEditStatus?.contactDetails}
                />
            </Route>
        </Switch>
    );
};
