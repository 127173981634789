import { PaymentPageProps } from './PaymentPage';
import { action } from '@storybook/addon-actions';
import { exampleImage } from './ExampleImage';
import { EarlySettlementPaymentResult } from 'common';

export const PaymentPagePropsWithAllValues: PaymentPageProps = {
    headline: 'Zaplaťte pomocou QR kódu alebo bankovým prevodom',
    description:
        'Platbu môžete zrealizovať jednoducho pomocou načítaním QR kódu prostredníctvom svojho mobilného zariadenia. Prípadne môžete platbu uhradiť bankovým prevodom v zmysle nižšie uvedených platobných údajov',
    manualBankTransferList: [
        {
            label: 'Názov banky',
            value: 'Some Bank',
        },
        {
            label: 'IBAN',
            value: 'DE 89 37040044 0532013000',
        },
        {
            label: 'Variabilný symbol/referencia platiteľa',
            value: '12345',
        },
        {
            label: 'Celková suma k úhrade',
            value: '2000,00 €',
        },
        {
            label: 'Vami zvolená čiastka na úhradu',
            value: '20,12 €',
        },
    ],
    payBySquareList: [
        {
            label: 'Celková suma k úhrade',
            value: '2000,00 €',
        },
        {
            label: 'Vami zvolená čiastka na úhradu',
            value: '20,12 €',
        },
    ],
    handleBackToPreviousView: action('handleBackToPreviousView'),
    payBySquareImage: {
        image: exampleImage,
        format: 'png',
        width: 256,
        height: 300,
    },
};

export const PaymentPagePropsWithoutImage: PaymentPageProps = {
    ...PaymentPagePropsWithAllValues,
    payBySquareImage: {
        image: '',
    },
};

export const EarlySettlementPaymentResultWithAllData: EarlySettlementPaymentResult = {
    bankName: 'Some Bank',
    iban: 'SK 89 37040044 0532013000',
    referenceCode: '12345',
    payBySquareImage: {
        image: exampleImage,
        format: 'png',
        width: 256,
        height: 300,
    },
    paymentAmount: 890.2,
    validityPeriodOfTheQuotation: '2024-10-11',
};
