import React from 'react';
import { PaymentDetailsPaymentResult } from 'common';
import { useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';
import { getPaymentDetailsPaymentPageProps, PaymentPage } from '../../payment-page';
import { useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';

export type OnlinePaymentViewProps = {
    generatePaymentResult: PaymentDetailsPaymentResult;
    handleBackToOverview: () => void;
};

export const OnlinePaymentView: React.FC<OnlinePaymentViewProps> = ({
    generatePaymentResult,
    handleBackToOverview,
}) => {
    const { t, f } = useTranslationWithFormatting('payment-details');
    useAnalyticsPageViewTracker('onPayment');

    return <PaymentPage {...getPaymentDetailsPaymentPageProps(t, f, handleBackToOverview, generatePaymentResult)} />;
};
