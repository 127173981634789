import { EarlySettlementLoadingPlaceholder } from '@cp-shared-5/frontend-ui';
import { EarlySettlement as CommonEarlySettlement } from 'common';
import { useContract } from 'components/contracts/useContract';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { dashboardPagePath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { TotalEarlySettlementUi } from './ui/TotalEarlySettlementUi';
import { isTotalEarlySettlementAvailable } from '../utils';

const TotalEarlySettlementWithHandlers = withLoadingAndNoConnectionHandler(TotalEarlySettlementUi);

export type TotalEarlySettlementProps = {
    encryptedContractNumber: string;
};

export const TotalEarlySettlement: React.FC<TotalEarlySettlementProps> = ({ encryptedContractNumber }) => {
    const [earlySettlement, setEarlySettlement] = useState<CommonEarlySettlement>();
    const [isLoadingEarlySettlement, setIsLoadingEarlySettlement] = useState<boolean>(false);
    const [loadingErrorEarlySettlement, setLoadingErrorEarlySettlement] = useState<boolean>(false);
    const [loadingStarted, setLoadingStarted] = useState(false);
    const history = useHistory();

    const {
        data: contract,
        isLoading: isLoadingContracts,
        loadingError: loadingErrorContract,
    } = useContract(encryptedContractNumber, { encryptedContractNumber: true });

    useEffect(() => {
        if (isLoadingContracts) setLoadingStarted(true);
    }, [isLoadingContracts]);

    useEffect(() => {
        if (
            (loadingStarted && isLoadingContracts === false && !loadingErrorContract && !contract) ||
            (contract && !isTotalEarlySettlementAvailable(contract))
        ) {
            history.push(dashboardPagePath());
        }
    }, [loadingStarted, isLoadingContracts, loadingErrorContract, contract, history]);

    useEffect(() => {
        if (contract?._links?.earlySettlement) {
            setIsLoadingEarlySettlement(true);
            CpDataApi.get(contract?._links?.earlySettlement)
                .then((response) => {
                    setEarlySettlement(response.data);
                })
                .catch((_) => {
                    setLoadingErrorEarlySettlement(true);
                })
                .finally(() => {
                    setIsLoadingEarlySettlement(false);
                });
        }
    }, [contract]);

    const isLoading = isLoadingContracts || isLoadingEarlySettlement;
    const hasError = !!loadingErrorContract || loadingErrorEarlySettlement;

    return (
        <TotalEarlySettlementWithHandlers
            contract={contract}
            earlySettlement={earlySettlement}
            isLoading={isLoading}
            hasError={!!hasError}
            encryptedContractNumber={encryptedContractNumber}
            loadingPlaceholder={<EarlySettlementLoadingPlaceholder withDividedContractHeader summaryElements={1} />}
        />
    );
};
