import React from 'react';
import { FinancialDetailsUi } from './ui';
import { useTranslation } from 'react-i18next';
import { AccordionItem } from '@cp-shared-5/frontend-ui';
import { ContractType, FinancialDetails as FinancialDetailsType } from 'common';
import { withLoadingAndNoConnectionHandler } from '../../../integration-wrapper';

export type FinancialDetailsProps = {
    financialDetails?: FinancialDetailsType;
    contractType: ContractType;
    encryptedContractNumber: string;
    changeMileageAvailable?: boolean;
    changeContractDurationAvailable?: boolean;
    paymentDateAvailable?: boolean;
    isLoading: boolean;
    hasError: boolean;
};

const FinancialDetailsUiWithHandlers = withLoadingAndNoConnectionHandler(FinancialDetailsUi);

export const FinancialDetails: React.FC<FinancialDetailsProps> = ({
    financialDetails,
    contractType,
    encryptedContractNumber,
    changeMileageAvailable,
    changeContractDurationAvailable,
    paymentDateAvailable,
    isLoading,
    hasError,
}) => {
    const { t } = useTranslation('financial-details');

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-none'}>
            <div>
                <FinancialDetailsUiWithHandlers
                    contractType={contractType}
                    financialDetails={financialDetails}
                    changeMileageAvailable={changeMileageAvailable}
                    changeContractDurationAvailable={changeContractDurationAvailable}
                    paymentDateAvailable={paymentDateAvailable}
                    encryptedContractNumber={encryptedContractNumber}
                    isLoading={isLoading}
                    hasError={hasError}
                />
            </div>
        </AccordionItem>
    );
};
