import React from 'react';
import { CustomerType, MyProfileData } from 'common';
import { ContactSection } from '../contact-section';
import { AddressesSection } from '../addresses-section';
import { MarketingConsentSettingsSection } from '../marketing-consent-settings-section/MarketingConsentSettingsSection';
import { IdentificationSection } from '../identification-section';
import { BankSection } from '../bank-section';

export const MyProfileUi: React.FC<{ myProfileData?: MyProfileData; customerType?: CustomerType }> = ({
    myProfileData,
    customerType,
}) => {
    if (!myProfileData) {
        return null;
    }
    const { identification, addresses, contactDetails, iban, marketingConsentSettings } = myProfileData;
    return (
        <>
            <IdentificationSection identification={identification} />
            <BankSection iban={iban} />
            <AddressesSection
                addresses={addresses}
                customerType={customerType || marketingConsentSettings?.customerType}
            />
            <ContactSection contactDetails={contactDetails} />
            {marketingConsentSettings?.customerType === 'Private' && (
                <MarketingConsentSettingsSection marketingConsentSettings={marketingConsentSettings} />
            )}
        </>
    );
};
