import {
    Contract,
    EarlySettlement,
    EarlySettlementPaymentResult,
    GeneratePayment,
    getGeneratePaymentEndpoint,
} from 'common';
import { Button, ButtonContainer, Layout } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { Spinner } from '@cp-shared-5/frontend-ui';
import { EarlySettlementDetailsView } from './details/EarlySettlementDetailsView';
import { useHistory } from 'react-router';
import { dashboardPagePath } from 'components/navigation/paths';
import { CpDataApi } from 'cp-xhr';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { EarlySettlementDownload } from '../../total-early-settlement/download/EarlySettlementDownload';

export type EarlySettlementActiveProps = {
    contract: Contract;
    earlySettlement: EarlySettlement;
    onSuccess: (paymentData: EarlySettlementPaymentResult) => void;
    onError: () => void;
    encryptedContractNumber: string;
    contractNumber: string;
};

export const EarlySettlementActive: React.FC<EarlySettlementActiveProps> = ({
    contract,
    earlySettlement,
    onSuccess,
    onError,
    encryptedContractNumber,
    contractNumber,
}) => {
    const { t } = useTranslationWithFormatting('total-early-settlement');
    const history = useHistory();
    const [isProcessing, setIsProcessing] = useState(false);

    const handleProceedToPayment = (): void => {
        const paymentAmount = earlySettlement?.details?.totalSettlementAmount;
        if (paymentAmount && contract.encryptedContractNumber) {
            setIsProcessing(true);
            const body: GeneratePayment = { paymentAmount };
            CpDataApi.post(getGeneratePaymentEndpoint(contract.encryptedContractNumber), body)
                .then((result) => {
                    onSuccess(result.data);
                })
                .catch(() => {
                    onError();
                })
                .finally((): void => {
                    setIsProcessing(false);
                });
        } else {
            onError();
        }
    };

    return (
        <>
            <EarlySettlementDownload
                encryptedContractNumber={encryptedContractNumber}
                contractNumber={contractNumber}
            />
            <Layout>
                <Layout.Item default="1/1">
                    <EarlySettlementDetailsView details={earlySettlement.details} />
                </Layout.Item>
                <Layout.Item default="1/1">
                    <div dangerouslySetInnerHTML={{ __html: t('details.additional-details') }} />
                </Layout.Item>
                <Layout.Item default="1/1">
                    <ButtonContainer center>
                        <Button
                            secondary
                            onClick={(): void => history.push(dashboardPagePath())}
                            testId="cancel-button"
                            type="btn"
                        >
                            {t('buttons.back')}
                        </Button>
                        {isProcessing ? (
                            <Spinner center />
                        ) : (
                            <Button onClick={handleProceedToPayment} testId="proceed-to-payment" type="btn">
                                {t('buttons.proceed')}
                            </Button>
                        )}
                    </ButtonContainer>
                </Layout.Item>
            </Layout>
        </>
    );
};
