import React from 'react';
import { BusinessCustomerData, CustomerType, EditStatus, Identification, PrivateCustomerData } from 'common';
import { useUpdateMyProfile } from '../useUpdateMyProfile';
import { changeIdentificationPath } from '../../navigation/paths';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { CompanyForm, PrivateForm } from './edit-view';
import { ConsultView } from './consult-view';
import { useAnalyticsActionTracker, useAnalyticsFormTracker } from '@cp-shared-5/frontend-ui';

export const IdentificationSection: React.FC<{ identification?: Identification; customerType?: CustomerType }> = ({
    identification,
}) => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { updateMyProfile, myProfileData } = useUpdateMyProfile();

    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'onEditProfileIdentificationSubmitFailed',
        confirmSuccess: 'onEditProfileIdentificationSuccess',
    });
    const { onAction: onStart } = useAnalyticsActionTracker('onEditProfileIdentification');
    const { onAction: onCancel } = useAnalyticsActionTracker('onEditProfileCancel');

    if (!identification) {
        return null;
    }

    const setLastEditStatus = (
        newEditStatus: EditStatus,
        updatedIdentificationData?: PrivateCustomerData | BusinessCustomerData,
    ): void => {
        updateMyProfile({
            ...myProfileData,
            identification: {
                ...myProfileData.identification,
                data: updatedIdentificationData ?? identification.data,
            },
            lastEditStatus: { ...myProfileData?.lastEditStatus, identification: newEditStatus },
        });
    };

    const startEditing = (): void => {
        onStart();
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changeIdentificationPath());
    };

    const cancelEditing = (): void => {
        onCancel('Identification');
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(path);
    };

    const finishEditing = (
        newEditStatus: EditStatus,
        updatedIdentificationData?: PrivateCustomerData | BusinessCustomerData,
    ): void => {
        newEditStatus === EditStatus.SUCCESS ? onSuccess() : onError();
        setLastEditStatus(newEditStatus, updatedIdentificationData);
        history.push(path);
    };

    return (
        <Switch>
            <Route exact path={changeIdentificationPath()}>
                {identification.customerType === 'Business' && (
                    <CompanyForm
                        identification={identification}
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                    />
                )}
                {identification.customerType === 'Private' && (
                    <PrivateForm
                        identification={identification}
                        cancelEditing={cancelEditing}
                        finishEditing={finishEditing}
                    />
                )}
            </Route>
            <Route path={path}>
                <ConsultView
                    identification={identification}
                    lastEditStatus={myProfileData?.lastEditStatus?.identification}
                    startEditing={startEditing}
                />
            </Route>
        </Switch>
    );
};
