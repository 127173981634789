import React from 'react';
import { AddressType, CustomerType, EditStatus, FiscalAddress, PostalAddress } from 'common';
import { CommonConsultView } from '../common/consult-view';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { addressValuesAreEmpty } from '../utils';
import { changePostalAddressPath } from '../../../navigation/paths';
import { CommonEditView } from '../common/edit-view';

type PostalAddressSectionProps = {
    address?: PostalAddress;
    customerType?: CustomerType;
    lastEditStatus?: EditStatus;
    startEditing: () => void;
    cancelEditing: (addressType: AddressType) => void;
    finishEditing: (addressType: AddressType, newEditStatus: EditStatus, updatedAddress?: FiscalAddress) => void;
};

export const PostalAddressSection: React.FC<PostalAddressSectionProps> = ({
    address,
    customerType,
    lastEditStatus,
    startEditing,
    cancelEditing,
    finishEditing,
}) => {
    const { path } = useRouteMatch();

    if (!address || addressValuesAreEmpty(address)) {
        return null;
    }
    return (
        <Switch>
            <Route exact path={changePostalAddressPath()}>
                <CommonEditView
                    addressType={AddressType.POSTAL}
                    address={address}
                    cancelEditing={cancelEditing}
                    finishEditing={finishEditing}
                    customerType={customerType}
                />
            </Route>
            <Route path={path}>
                <CommonConsultView
                    address={address}
                    addressType={AddressType.POSTAL}
                    customerType={customerType}
                    lastEditStatus={lastEditStatus}
                    startEditing={startEditing}
                />
            </Route>
        </Switch>
    );
};
