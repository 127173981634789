import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { areAllFilesValid } from '../../../../file-upload/helpers';
import { CompanyNameError, companyNameValidator, VatNumberError, vatNumberValidator } from 'common';

export const validationSchema = (t: TFunction, prevCompanyName: string, prevVatNumber: string): Yup.AnySchema => {
    const translationPrefix = 'identification.edit-view.business.validation-errors';
    const companyNameError: CompanyNameError = {
        max: t(`${translationPrefix}.company-name.max-length`),
        required: t(`${translationPrefix}.company-name.missing`),
    };
    const vatNumberError: VatNumberError = {
        max: t(`${translationPrefix}.vat-number.max-length`),
        matches: t(`${translationPrefix}.vat-number.max-length`),
        required: t(`${translationPrefix}.vat-number.missing`),
    };

    return Yup.object().shape(
        {
            companyName: companyNameValidator(companyNameError).when('vatNumber', {
                is: (vatNumber: string) => {
                    return vatNumber === prevVatNumber;
                },
                then: Yup.string().test(
                    'sameValues',
                    t(`${translationPrefix}.nothing-changed`),
                    (companyName?: string): boolean => {
                        return companyName !== undefined && companyName !== prevCompanyName;
                    },
                ),
            }),
            vatNumber: vatNumberValidator(vatNumberError),
            companyNameFiles: Yup.array().when('companyName', {
                is: (companyName: string) => companyName !== prevCompanyName,
                then: Yup.array()
                    .min(1, t('file-upload:mandatory-error'))
                    .test('files', '', (files) => areAllFilesValid(files as File[])),
            }),
            vatNumberFiles: Yup.mixed().when('vatNumber', {
                is: (vatNumber: string) => vatNumber !== prevVatNumber && !!vatNumber,
                then: Yup.array()
                    .min(1, t('file-upload:mandatory-error'))
                    .test('files', '', (files) => areAllFilesValid(files as File[])),
            }),
        },
        [['companyName', 'vatNumber']],
    );
};
