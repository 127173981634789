import { getRequestMarketingCardEndpoint, MarketingCard } from 'common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<MarketingCard[]>({
    contentName: 'RequestMarketingCard',
    contentEndpoint: getRequestMarketingCardEndpoint,
});

export default reducer;
export const fetchRequestMarketingCard = fetchContent;
