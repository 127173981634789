import { uploadFilesDirectToMediaStorage } from '@cp-shared-5/frontend-integration';
import {
    CleaveInput,
    DataOverview,
    Notification,
    NotificationStatus,
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
    ValidatedInput,
    withAutoScroll,
} from '@cp-shared-5/frontend-ui';
import {
    AddressType,
    CustomerType,
    DATE_FORMAT,
    EditStatus,
    FileInfo,
    FiscalAddress,
    getChangeFiscalAddressEndpoint,
    getChangePostalAddressEndpoint,
    getDeleteFiscalAddressEndpoint,
    IdCardType,
    PostalAddress,
    UpdateFiscalAddress,
    UpdatePostalAddress,
} from 'common';
import { Button, ButtonContainer, Fieldset, Form, Layout, Paragraph } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { Formik, FormikErrors, FormikValues } from 'formik';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DOCTYPE_ID_BACK, DOCTYPE_ID_BUSINESS, DOCTYPE_ID_FRONT } from '../../utils';
import { DeleteConfirmation } from '../delete-confirmation';
import { SummaryView } from '../summary-view/SummaryView';
import { CitySelection } from './city-selection';
import { AddressFormValues, getInitialValues } from './initialValues';
import { PostalAddressFormFields } from './PostalAddressFormFields';
import { validationSchema } from './validationSchema';
import { formatCpDate } from '@cp-shared-5/common-utilities';

type EditViewProps = {
    addressType: AddressType;
    address?: PostalAddress | FiscalAddress;
    cancelEditing: (addressType: AddressType) => void;
    finishEditing: (addressType: AddressType, newEditStatus: EditStatus, updatedAddress?: FiscalAddress) => void;
    customerType?: CustomerType;
};

export const CommonEditView: React.FC<EditViewProps> = withAutoScroll(
    ({ addressType, address = {}, cancelEditing, finishEditing, customerType }) => {
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [showEditConfirmation, setShowEditConfirmation] = useState(false);
        const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

        const { t } = useTranslation('my-profile-addresses-details');
        const isFiscal = addressType === AddressType.FISCAL;
        const translationPrefix = isFiscal ? 'edit-view.fiscal' : 'edit-view.postal';
        const commonTranslationPrefix = 'edit-view.common';
        const description = customerType === 'Private' ? 'private-description' : 'company-description';

        const trackingSection = 'Address';
        useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, trackingSection);
        const { onTyping } = useAnalyticsFormTracker({
            startTyping: 'onEditProfileAddressTypedIn',
        });
        const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileAddressValidationError');
        const { onAction: onConfirmation } = useAnalyticsActionTracker('onEditProfileConfirmation');
        const { onAction: onConfirmationCancel } = useAnalyticsActionTracker('onEditProfileConfirmationCancel');

        const getInitialErrors = (values: FormikValues): string =>
            ['street', 'postalCode', 'city'].filter((element: string): boolean => values[element] === '').join(', ');
        const getErrors = (errors: FormikErrors<FormikValues>): string => Object.keys(errors).join(`, `);

        const onFiscalSubmit = (values: AddressFormValues): void => {
            setIsSubmitting(true);
            const { city, country, postalCode, secondLine, street } = values.address;
            const body: UpdateFiscalAddress = {
                address: { city, country, postalCode, secondLine, street },
            };
            CpDataApi.put(getChangeFiscalAddressEndpoint(), body)
                .then(() => {
                    finishEditing(addressType, EditStatus.SUCCESS, { city, country, postalCode, secondLine, street });
                })
                .catch(() => {
                    finishEditing(addressType, EditStatus.ERROR);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        };

        const onPostalSubmitPrivate = (values: AddressFormValues): void => {
            setIsSubmitting(true);
            const { address, personIdCardData, backOfDocument, frontOfDocument } = values;

            const backOfDocumentFile = uploadFilesDirectToMediaStorage(backOfDocument, CpDataApi);
            const frontOfDocumentFile = uploadFilesDirectToMediaStorage(frontOfDocument, CpDataApi);

            const doAfterSuccessfulUpload = (backOfDocumentId: string[], frontOfDocumentId: string[]): void => {
                const body: UpdatePostalAddress = {
                    address,
                    personIdCardData: {
                        type: personIdCardData?.type as IdCardType,
                        number: personIdCardData?.number,
                        issueDate: formatCpDate(personIdCardData?.issueDate, DATE_FORMAT).toCpDate(),
                        issueCity: personIdCardData?.issueCity,
                    },
                    files: [
                        ...backOfDocumentId.map((id: string): FileInfo => {
                            return {
                                docTypeId: DOCTYPE_ID_BACK,
                                fileId: id,
                            };
                        }),
                        ...frontOfDocumentId.map((id: string): FileInfo => {
                            return {
                                docTypeId: DOCTYPE_ID_FRONT,
                                fileId: id,
                            };
                        }),
                    ],
                };

                CpDataApi.put(getChangePostalAddressEndpoint(), body)
                    .then(() => {
                        finishEditing(addressType, EditStatus.SUCCESS, address);
                    })
                    .catch(() => {
                        finishEditing(addressType, EditStatus.ERROR);
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    });
            };

            Promise.all([backOfDocumentFile, frontOfDocumentFile])
                .then(([backOfDocumentId, frontOfDocumentId]) =>
                    doAfterSuccessfulUpload(backOfDocumentId, frontOfDocumentId),
                )
                .catch(() => {
                    finishEditing(addressType, EditStatus.ERROR);
                    setIsSubmitting(false);
                });
        };

        const onPostalSubmitCompany = (values: AddressFormValues): void => {
            setIsSubmitting(true);
            const { city, country, postalCode, street } = values.address;

            const doAfterSuccessfulUpload = (registryDocumentId: string): void => {
                const body: UpdatePostalAddress = {
                    address: {
                        city,
                        country,
                        postalCode,
                        street,
                    },
                    files: [
                        {
                            docTypeId: DOCTYPE_ID_BUSINESS,
                            fileId: registryDocumentId,
                        },
                    ],
                };

                CpDataApi.put(getChangePostalAddressEndpoint(), body)
                    .then(() => {
                        finishEditing(addressType, EditStatus.SUCCESS, values.address);
                    })
                    .catch(() => {
                        finishEditing(addressType, EditStatus.ERROR);
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    });
            };

            uploadFilesDirectToMediaStorage(values.registryDocument, CpDataApi)
                .then(([frontOfDocumentId]) => doAfterSuccessfulUpload(frontOfDocumentId))
                .catch(() => {
                    finishEditing(addressType, EditStatus.ERROR);
                    setIsSubmitting(false);
                });
        };

        const onDeleteAddress = (): void => {
            setIsSubmitting(true);
            setShowDeleteConfirmation(false);
            CpDataApi.delete(getDeleteFiscalAddressEndpoint())
                .then(() => {
                    finishEditing(AddressType.FISCAL, EditStatus.DELETE_SUCCESS, {});
                })
                .catch(() => {
                    finishEditing(AddressType.FISCAL, EditStatus.ERROR);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        };

        const onSubmit = (): void => {
            onConfirmation(trackingSection);
            setShowEditConfirmation(true);
        };

        const onClose = (): void => {
            onConfirmationCancel(trackingSection);
            setShowEditConfirmation(false);
        };

        const onConfirm = (values: AddressFormValues): void => {
            setShowEditConfirmation(false);
            if (isFiscal) onFiscalSubmit(values);
            else if (customerType === 'Private') onPostalSubmitPrivate(values);
            else onPostalSubmitCompany(values);
        };

        return (
            <DataOverview title={t(`${translationPrefix}.headline`)} withoutCardEffect={true} className={'u-pt-none'}>
                <Formik
                    enableReinitialize
                    initialValues={getInitialValues(addressType, address, t)}
                    validationSchema={validationSchema(addressType, t, customerType)}
                    onSubmit={onSubmit}
                >
                    {({ submitForm, errors, touched, values }): JSX.Element => (
                        <Form
                            onSubmit={preventSubmit()}
                            data-testid="edit-form"
                            onChange={(): void => onTyping(errors, touched)}
                        >
                            {showEditConfirmation ? (
                                <SummaryView
                                    addressType={addressType}
                                    newData={values.address}
                                    onConfirm={(): void => onConfirm(values)}
                                    onClose={onClose}
                                />
                            ) : showDeleteConfirmation ? (
                                <DeleteConfirmation
                                    onCancel={(): void => setShowDeleteConfirmation(false)}
                                    onConfirm={onDeleteAddress}
                                />
                            ) : (
                                <UiBlockingSpinner isBlocking={isSubmitting}>
                                    {isFiscal ? (
                                        <Notification
                                            status={NotificationStatus.info}
                                            text={t(`${translationPrefix}.info`)}
                                            testId={'address-change-info'}
                                            className="u-mb"
                                        />
                                    ) : (
                                        <>
                                            <Paragraph>{t(`${translationPrefix}.info`)}</Paragraph>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: t(`${translationPrefix}.${description}`),
                                                }}
                                            />
                                        </>
                                    )}
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item default={'1/2'} s="1/1">
                                                    <ValidatedInput
                                                        label={t(`${commonTranslationPrefix}.street.label`)}
                                                        tooltip={t(`${commonTranslationPrefix}.street.tooltip`)}
                                                        name="address.street"
                                                        testId="address.street"
                                                        type="text"
                                                        isMandatory
                                                    />
                                                </Layout.Item>
                                                {isFiscal && (
                                                    <Layout.Item default="1/2" s="1/1">
                                                        <ValidatedInput
                                                            label={t(`${translationPrefix}.second-line.label`)}
                                                            tooltip={t(`${translationPrefix}.second-line.tooltip`)}
                                                            name="address.secondLine"
                                                            testId="address.secondLine"
                                                            type="text"
                                                        />
                                                    </Layout.Item>
                                                )}
                                                <Layout.Item default={isFiscal ? '1/4' : '1/2'} s="1/1">
                                                    <CleaveInput
                                                        cleaveOptions={{
                                                            delimiter: '',
                                                            blocks: [5],
                                                            numericOnly: true,
                                                        }}
                                                        inputMode={'numeric'}
                                                        label={t(`${commonTranslationPrefix}.postal-code.label`)}
                                                        tooltip={t(`${commonTranslationPrefix}.postal-code.tooltip`)}
                                                        name="address.postalCode"
                                                        testId="address.postalCode"
                                                        isMandatory
                                                    />
                                                </Layout.Item>
                                                <Layout.Item default="1/2" s="1/1">
                                                    <CitySelection
                                                        currentCitySelection={values.address.city}
                                                        name="address.city"
                                                        testId="address.city"
                                                        isMandatory
                                                    />
                                                </Layout.Item>
                                                <Layout.Item default={isFiscal ? '1/4' : '1/2'} s="1/1">
                                                    <ValidatedInput
                                                        label={t(`${commonTranslationPrefix}.country.label`)}
                                                        name="address.country"
                                                        testId="address.country"
                                                        type="text"
                                                        disabled={true}
                                                        isMandatory
                                                    />
                                                </Layout.Item>
                                                {!isFiscal && <PostalAddressFormFields customerType={customerType} />}
                                            </Layout>
                                        </Fieldset.Row>
                                        <Fieldset.Row>
                                            <ButtonContainer center>
                                                <Button
                                                    secondary
                                                    onClick={(): void => {
                                                        cancelEditing(addressType);
                                                    }}
                                                    testId="cancel-button"
                                                    type="button"
                                                >
                                                    {t(`translation:editable-section-nav.cancel`)}
                                                </Button>
                                                {addressType === AddressType.FISCAL && (
                                                    <Button
                                                        secondary
                                                        onClick={(): void => setShowDeleteConfirmation(true)}
                                                        testId="delete-button"
                                                        type="button"
                                                    >
                                                        {t(`translation:editable-section-nav.delete`)}
                                                    </Button>
                                                )}
                                                <Button
                                                    onClick={async (): Promise<void> => {
                                                        await submitForm();
                                                        const initialErrors = getInitialErrors(values);
                                                        if (!isEmpty(errors)) {
                                                            const errorsList = getErrors(errors);
                                                            onValidationError(errorsList);
                                                        } else if (!!initialErrors) {
                                                            onValidationError(initialErrors);
                                                        }
                                                    }}
                                                    testId="submit-button"
                                                    type="submit"
                                                >
                                                    {t(`translation:editable-section-nav.change`)}
                                                </Button>
                                            </ButtonContainer>
                                        </Fieldset.Row>
                                    </Fieldset>
                                </UiBlockingSpinner>
                            )}
                        </Form>
                    )}
                </Formik>
            </DataOverview>
        );
    },
    'AddressEditView',
);
