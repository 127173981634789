import React, { useEffect, useState } from 'react';
import {
    ContractWithLinks,
    EarlySettlement as CommonEarlySettlement,
    getMarketApiHealthEndpoint,
    MarketApiHealthResponse,
} from 'common';
import { useTranslation } from 'react-i18next';
import { Button, ButtonContainer, Layout } from '@vwfs-bronson/bronson-react';
import { EarlySettlementHeader } from '../../header/EarlySettlementHeader';
import { CpDataApi } from 'cp-xhr';
import { OptionCalculatorForm } from './form/OptionCalculatorForm';
import { SettlementOptions } from './options/SettlementOptions';
import { useHistory } from 'react-router-dom';
import { earlySettlementPath } from 'components/navigation/paths';
import { Notification, NotificationStatus, Spinner } from '@cp-shared-5/frontend-ui';

export type CalculationProps = {
    contract: ContractWithLinks;
    encryptedContractNumber: string;
    setSelectedOption: Function;
    setAcceptRequest: Function;
    continueToQuoteDetails: () => void;
};

export const Calculation: React.FC<CalculationProps> = ({
    contract,
    encryptedContractNumber,
    setSelectedOption,
    setAcceptRequest,
    continueToQuoteDetails,
}) => {
    const { t } = useTranslation('partial-early-settlement');
    const history = useHistory();
    const [earlySettlement, setEarlySettlement] = useState<CommonEarlySettlement>();
    const [isLoadingEarlySettlement, setIsLoadingEarlySettlement] = useState<boolean>(false);
    const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);
    const [isApiReachable, setIsApiReachable] = useState<boolean>(true);

    useEffect(() => {
        CpDataApi.get<MarketApiHealthResponse>(getMarketApiHealthEndpoint())
            .then((res) => {
                if (res?.data?.status === 'OK' && res?.data?.connections === 'OK') {
                    setIsApiReachable(true);
                } else {
                    setIsApiReachable(false);
                }
            })
            .catch((_) => {
                setIsApiReachable(false);
            });
    }, []);

    useEffect(() => {
        if (contract?._links?.earlySettlement) {
            setIsLoadingEarlySettlement(true);
            CpDataApi.get(contract?._links?.earlySettlement)
                .then((response) => {
                    setEarlySettlement(response.data);
                })
                .catch((_) => {
                    setIsApiReachable(false);
                })
                .finally(() => {
                    setIsLoadingEarlySettlement(false);
                });
        }
    }, [contract]);

    const [calculationOptions, setCalculatedOptions] = useState();

    return (
        <>
            {isLoadingEarlySettlement && <Spinner fullPage />}
            {isApiReachable ? (
                <>
                    <Layout.Item default="1/1" s="1/1">
                        <EarlySettlementHeader
                            registrationNumber={contract.licensePlate}
                            carInformation={contract.brandModelType!}
                            productName={contract.productName}
                            contractNumber={contract.contractNumber!}
                            summary={earlySettlement?.summary}
                        />
                    </Layout.Item>
                    <OptionCalculatorForm
                        encryptedContractNumber={encryptedContractNumber}
                        setAcceptRequest={setAcceptRequest}
                        setSelectedOption={setSelectedOption}
                        setIsOptionSelected={setIsOptionSelected}
                        setCalculatedOptions={setCalculatedOptions}
                    />
                    {calculationOptions && (
                        <SettlementOptions
                            setSelectedOption={setSelectedOption}
                            setIsOptionSelected={setIsOptionSelected}
                            contractType={contract?.contractType}
                            setAcceptRequest={setAcceptRequest}
                            partialEarlySettlementDetails={calculationOptions}
                        />
                    )}
                </>
            ) : (
                <Notification
                    className="u-text-left u-mt-large"
                    status={NotificationStatus.error}
                    text={t('calculate.errors.be-not-available')}
                />
            )}
            <Layout.Item className="u-mt" default="1/1" s="1/1">
                <ButtonContainer center>
                    <Button
                        secondary
                        onClick={(): void => history.push(earlySettlementPath(encryptedContractNumber))}
                        testId="calculationBackButton"
                    >
                        {t('calculate.dashboard-button')}
                    </Button>
                    <Button
                        testId="continueButton"
                        type="btn"
                        onClick={continueToQuoteDetails}
                        disabled={!isOptionSelected}
                    >
                        {t('calculate.continue-button')}
                    </Button>
                </ButtonContainer>
            </Layout.Item>
        </>
    );
};
