import React from 'react';

import { Card, Layout, SectionHeading, Heading, ResponsiveImage } from '@vwfs-bronson/bronson-react';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { GeneratePaymentResult } from 'common';

type PaymentDetailsProps = {
    paymentDetails?: GeneratePaymentResult;
};

export const PaymentDetails: React.FC<PaymentDetailsProps> = ({ paymentDetails }) => {
    const { t, f } = useTranslationWithFormatting('partial-early-settlement');

    const payBySquareList: DefinitionListItem[] = [
        {
            label: t('offline-payment.pay-by-square.total-due-amount'),
            value: f(paymentDetails?.paymentAmount, TranslationFormat.CURRENCY),
        },
    ].filter((item) => !!item.value);

    const bankTransferList: DefinitionListItem[] = [
        {
            label: t('offline-payment.manual-bank-transfer.bank-name'),
            value: paymentDetails?.bankName,
        },
        {
            label: t('offline-payment.manual-bank-transfer.iban'),
            value: paymentDetails?.iban,
        },
        {
            label: t('offline-payment.manual-bank-transfer.reference-code'),
            value: paymentDetails?.referenceCode,
        },
        {
            label: t('offline-payment.manual-bank-transfer.total-due-amount'),
            value: f(paymentDetails?.paymentAmount, TranslationFormat.CURRENCY),
        },
    ].filter((item) => !!item.value);

    return (
        <>
            <Layout.Item default="1/1">
                <SectionHeading level="3" subtitle={t('offline-payment.text')}>
                    {t('offline-payment.headline')}
                </SectionHeading>
            </Layout.Item>
            <Layout.Item default="1/1">
                <Layout equalHeight>
                    <Layout.Item default="1/2" s="1/1">
                        <Card element="article">
                            <Heading className="u-text-left" level="5">
                                {t('offline-payment.pay-by-square.headline')}
                            </Heading>
                            <DefinitionListHorizontal className="u-text-left" list={payBySquareList} />
                            <ResponsiveImage
                                sizes="10px 10px"
                                className="u-text-left"
                                src={`data:image/png;base64,${paymentDetails?.payBySquareImage?.image}`}
                            ></ResponsiveImage>
                        </Card>
                    </Layout.Item>
                    <Layout.Item default="1/2" s="1/1">
                        <Card className="u-text-left" style={{ overflowWrap: 'break-word' }} element="article">
                            <Heading level="5">{t('offline-payment.manual-bank-transfer.headline')}</Heading>
                            <DefinitionListHorizontal list={bankTransferList} />
                        </Card>
                    </Layout.Item>
                </Layout>
            </Layout.Item>
        </>
    );
};
