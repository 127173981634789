import { Contract, EarlySettlement, EarlySettlementPaymentResult, GeneratePaymentResult } from 'common';
import { Layout } from '@vwfs-bronson/bronson-react';
import React, { useState } from 'react';
import { useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';
import { PaymentPage, getEarlySettlementPaymentPageProps } from 'components/payment-page';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { EarlySettlementHeader } from '../../header/EarlySettlementHeader';
import {
    EarlySettlementActive,
    EarlySettlementAllowed,
    EarlySettlementCalculating,
    EarlySettlementExpired,
    EarlySettlementForbidden,
} from '../../statuses';

export type TotalEarlySettlementUiProps = {
    contract?: Contract;
    earlySettlement?: EarlySettlement;
    encryptedContractNumber: string;
};

enum Step {
    EARLY_SETTLEMENT = 'EARLY_SETTLEMENT',
    PAYMENT = 'PAYMENT',
}

export const TotalEarlySettlementUi: React.FC<TotalEarlySettlementUiProps> = ({
    contract,
    earlySettlement,
    encryptedContractNumber,
}) => {
    const { t, f } = useTranslationWithFormatting('total-early-settlement');

    useAnalyticsPageViewTracker('earlySettlement', !!contract && !!earlySettlement);
    const { onAction } = useAnalyticsActionTracker('onEarlySettlementProceedToPayment');

    const [step, setStep] = useState<Step>(Step.EARLY_SETTLEMENT);
    const [paymentData, setPaymentData] = useState<EarlySettlementPaymentResult & { isError?: boolean }>();
    // Check for contract being available, otherwise it might be that fetching hasn't even started
    if (!contract || !earlySettlement) {
        return null;
    }

    const { brandModelType = '', contractNumber = '', licensePlate, productName } = contract;

    const handleBackToPreviousStep = (): void => setStep(Step.EARLY_SETTLEMENT);

    const onFinish = (): void => {
        setStep(Step.PAYMENT);
        onAction();
    };

    const onSuccess = (generatePaymentData: GeneratePaymentResult): void => {
        setPaymentData({
            ...generatePaymentData,
            validityPeriodOfTheQuotation: earlySettlement?.details?.validityPeriodOfTheQuotation,
        });
        onFinish();
    };

    const onError = (): void => {
        setPaymentData(() => ({ payBySquareImage: {}, isError: true }));
        onFinish();
    };

    return (
        <>
            <h3>{t('headline')}</h3>
            <Layout>
                <Layout.Item default="1/1" className="u-mb-large">
                    <EarlySettlementHeader
                        registrationNumber={licensePlate}
                        carInformation={brandModelType}
                        productName={productName}
                        contractNumber={contractNumber}
                        summary={earlySettlement.summary}
                    />
                </Layout.Item>
            </Layout>

            {step === Step.EARLY_SETTLEMENT ? (
                earlySettlement.earlySettlementStatusInfo.status === 'Active' ? (
                    <EarlySettlementActive
                        contract={contract}
                        earlySettlement={earlySettlement}
                        onSuccess={onSuccess}
                        onError={onError}
                        contractNumber={contractNumber}
                        encryptedContractNumber={encryptedContractNumber}
                    />
                ) : earlySettlement.earlySettlementStatusInfo.status === 'Allowed' ? (
                    <EarlySettlementAllowed contract={contract} />
                ) : earlySettlement.earlySettlementStatusInfo.status === 'Forbidden' ? (
                    <EarlySettlementForbidden earlySettlement={earlySettlement} />
                ) : earlySettlement.earlySettlementStatusInfo.status === 'Requested' ? (
                    <EarlySettlementCalculating />
                ) : earlySettlement.earlySettlementStatusInfo.status === 'Expired' ? (
                    <EarlySettlementExpired earlySettlement={earlySettlement} />
                ) : null
            ) : (
                <Layout>
                    <Layout.Item default="1/1" className={'u-mt-none'}>
                        <PaymentPage
                            {...getEarlySettlementPaymentPageProps(t, f, handleBackToPreviousStep, paymentData)}
                        />
                    </Layout.Item>
                </Layout>
            )}
        </>
    );
};
