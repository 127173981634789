import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { DATE_FORMAT } from 'common';
import { formatCpDate } from '@cp-shared-5/common-utilities';

export const personIdentificationFormValidationSchema = (t: TFunction) => {
    const translationPrefis = 'registration-form.person.validation-errors';
    return Yup.object().shape({
        privateCustomerNumber: Yup.string()
            .trim()
            .required(t(`${translationPrefis}.customer-number-missing`)),
        firstName: Yup.string()
            .trim()
            .required(t(`${translationPrefis}.name-missing`)),
        surname: Yup.string()
            .trim()
            .required(t(`${translationPrefis}.surname-missing`)),
        dateOfBirth: Yup.string()
            .required(t(`${translationPrefis}.birth-date.missing`))
            .test('format', t(`${translationPrefis}.birth-date.format`), (date) =>
                formatCpDate(date, DATE_FORMAT).isValid(),
            ),
        confirmTermsAndConditions: Yup.bool().oneOf([true], t(`${translationPrefis}.terms-and-conditions`)),
        confirmPrivacyPolicy: Yup.bool().oneOf([true], t(`${translationPrefis}.privacy-policy`)),
    });
};
