import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { LicensePlateError, licensePlateTextInputsValidator, UpdateLicensePlate } from 'common';
import { areAllFilesValid } from '../../../../../file-upload/helpers';

export const validationSchema = (t: TFunction): Yup.SchemaOf<Omit<UpdateLicensePlate, 'files'>> => {
    const translationPrefix = 'edit-view';
    const messages: Omit<LicensePlateError, 'files'> = {
        newLicensePlate: {
            format: t(`${translationPrefix}.license-plate.validation.format`),
            required: t(`${translationPrefix}.license-plate.validation.format`),
        },
        technicalDocumentNumber: {
            format: t(`${translationPrefix}.technical-document-number.validation.format`),
            required: t(`${translationPrefix}.technical-document-number.validation.format`),
        },
    };
    return licensePlateTextInputsValidator(messages).shape({
        frontOfDocument: Yup.array()
            .min(1, t('file-upload:mandatory-error'))
            .max(1, t('file-upload:max-one-file-error'))
            .test('files', '', (files) => areAllFilesValid(files as File[])),
        backOfDocument: Yup.array()
            .min(0)
            .max(1, t('file-upload:max-one-file-error'))
            .test('files', '', (files) => areAllFilesValid(files as File[])),
    });
};
