import {
    AutoCreditFinancialDetails,
    BallonCreditFinancialDetails,
    BaseFinancialDetails,
    ConsumerCreditFinancialDetails,
    FinancialDetails,
    FinancialLeasingFinancialDetails,
    OperativeLeasingFinancialDetails,
} from 'common';
import { formatCpDate } from '@cp-shared-5/common-utilities';

const ActiveBaseFinancialDetailsWithAllValues: BaseFinancialDetails = {
    isActive: true,
    contractStartDate: formatCpDate('2017-10-11T09:44:57.299').toCpDate(),
    contractEndDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    firstPaymentDate: formatCpDate('2017-11-11T09:44:57.299').toCpDate(),
    lastPaymentDate: formatCpDate('2019-10-11T09:44:57.299').toCpDate(),
    term: 36,
    downPayment: 150,
    balloonAmount: 250,
    monthlyDueDay: 15,
    nominalInterestRate: 350,
};

export const ActiveConsumerCreditFinancialDetailsWithAllValues: ConsumerCreditFinancialDetails = {
    ...ActiveBaseFinancialDetailsWithAllValues,
    financeValueOfContract: 450,
    pendingCapital: 550,
};

export const InactiveConsumerCreditFinancialDetailsWithAllValues: ConsumerCreditFinancialDetails = {
    ...ActiveConsumerCreditFinancialDetailsWithAllValues,
    downPayment: 0.0,
    nominalInterestRate: 0.0,
    isActive: false,
};

export const ActiveAutoCreditFinancialDetailsWithAllValues: AutoCreditFinancialDetails = {
    ...ActiveConsumerCreditFinancialDetailsWithAllValues,
};

export const InactiveAutoCreditFinancialDetailsWithAllValues: AutoCreditFinancialDetails = {
    ...InactiveConsumerCreditFinancialDetailsWithAllValues,
};

export const ActiveFinancialLeasingFinancialDetailsWithAllValues: FinancialLeasingFinancialDetails = {
    ...ActiveConsumerCreditFinancialDetailsWithAllValues,
};

export const InactiveFinancialLeasingFinancialDetailsWithAllValues: FinancialLeasingFinancialDetails = {
    ...InactiveConsumerCreditFinancialDetailsWithAllValues,
};

export const ActiveBallonCreditFinancialDetailsWithAllValues: BallonCreditFinancialDetails = {
    ...ActiveBaseFinancialDetailsWithAllValues,
    financeValueOfContract: 450,
    pendingCapital: 550,
    contractedMileAge: 650.0,
    expectedMileAgeToDate: 750.0,
};

export const InactiveBallonCreditFinancialDetailsWithAllValues: BallonCreditFinancialDetails = {
    ...ActiveBallonCreditFinancialDetailsWithAllValues,
    isActive: false,
};

export const ActiveOperativeLeasingFinancialDetailsWithAllValues: OperativeLeasingFinancialDetails = {
    ...ActiveBaseFinancialDetailsWithAllValues,
    contractedMileAge: 650,
    expectedMileAgeToDate: 750,
};

export const InactiveOperativeLeasingFinancialDetailsWithAllValues: OperativeLeasingFinancialDetails = {
    ...ActiveOperativeLeasingFinancialDetailsWithAllValues,
    isActive: false,
};

export const FinancialDetailsWithTermEqualToZero: FinancialDetails = {
    isActive: true,
    contractStartDate: formatCpDate('2019-10-11T09:44:57.299').toCpDate(),
    contractEndDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    term: 0,
};

export const FinancialDetailsWithMissingValues: FinancialDetails = {
    isActive: true,
    contractStartDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
};

export const FinancialDetailsInactiveContract: FinancialDetails = {
    isActive: false,
    contractStartDate: formatCpDate('2019-10-11T09:44:57.299').toCpDate(),
    contractEndDate: formatCpDate('2019-11-11T09:44:57.299').toCpDate(),
    term: 36,
};
