import React from 'react';
import { PaymentDetails, PaymentDetailsItem } from 'common';
import { Cell, createDynamicTable, DynamicTableFormatOptions } from '@cp-shared-5/frontend-ui';

import { generateRow, getColumnHeadings, getSummaryRow } from './utils';
import { useTranslationWithFormatting } from '../../../../localization/useTranslationWithFormatting';

type PaymentDetailsTableProps = {
    paymentDetails?: PaymentDetails;
};

const DynamicTable = createDynamicTable();

export const PaymentDetailsTable: React.FC<PaymentDetailsTableProps> = ({ paymentDetails }) => {
    const { t, f } = useTranslationWithFormatting('payment-details');

    if (!paymentDetails) return null;

    const formatOptions: DynamicTableFormatOptions = {
        auto: false,
        noScroll: true,
        colored: false,
        bordered: false,
        highlight: false,
        wide: true,
        narrow: false,
    };

    return (
        <DynamicTable
            rows={paymentDetails?.rows || []}
            columnHeadings={getColumnHeadings(t)}
            formatOptions={formatOptions}
            toColumnValues={(row: PaymentDetailsItem): Cell[] => generateRow(f, row)}
            hasSummary={true}
            summaryRow={getSummaryRow(f, t('total-due-amount'), paymentDetails.totalDueAmount)}
        />
    );
};
