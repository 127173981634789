import React from 'react';
import { ContractType, FinancialDetails } from 'common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import { getFinancingSectionListItems, EditStatus } from '../../utils';
import { Button, Heading } from '@vwfs-bronson/bronson-react';
import { PaymentDayNotification } from '../payment-day-notification/PaymentDayNotification';

type FinancingSectionProps = {
    contractType: ContractType;
    details: FinancialDetails;
    paymentDateAvailable?: boolean;
    startEditing: () => void;
    editStatus: EditStatus;
};

export const NonEditView: React.FC<FinancingSectionProps> = ({
    contractType,
    details,
    startEditing,
    editStatus,
    paymentDateAvailable,
}) => {
    const { t, f } = useTranslationWithFormatting('financial-details');
    if (!details) return null;

    const itemList: DefinitionListItem[] = getFinancingSectionListItems(t, f, contractType, details);

    return (
        <>
            <Heading className={'c-data-overview__header u-pt'} level={'6'}>
                {t('financing-section.headline')}
                {paymentDateAvailable && (
                    <Button
                        element="a"
                        link
                        small
                        className="c-data-overview__button u-mb-none u-mt-none u-mr-small"
                        icon="true"
                        iconReversed
                        onClick={startEditing}
                        testId={'edit-button'}
                    >
                        {t('translation:editable-section-nav.start')}
                    </Button>
                )}
            </Heading>
            <DefinitionList split={true} list={itemList} />
            <PaymentDayNotification editStatus={editStatus} />
        </>
    );
};
