import React, { useCallback } from 'react';
import { ContractInfo } from 'common';
import { useTranslation } from 'react-i18next';
import { Combobox, ErrorMessage, FormField, Layout } from '@vwfs-bronson/bronson-react';
import { RequestInitialValues } from '../initialValues';
import { FormikProps, useField, useFormikContext } from 'formik';

export type ContractSelectionProps = {
    contracts?: ContractInfo[];
    currentContractNumber?: string;
};

export const generalKey = `generalKey`;

export const ContractSelection: React.FC<ContractSelectionProps> = ({ contracts }) => {
    const [, meta, helpers] = useField('contractSelection');
    const { initialValues }: FormikProps<RequestInitialValues> = useFormikContext();
    const { t } = useTranslation('request');

    const getContractSelection = (selectedContractNumber: string) => {
        if (selectedContractNumber === generalKey) {
            return { contractNumber: generalKey };
        }

        const { isActive, brandModelType, contractNumber, contractType, productName }: ContractInfo = contracts?.find(
            ({ contractNumber }) => contractNumber === selectedContractNumber,
        ) || { isActive: true };

        return { isActive, brandModelType, contractNumber, contractType, productName };
    };

    const setValueChange = useCallback(
        (selectedContractNumber: { key: string; value: string }[]) => {
            const contractSelectionValues = getContractSelection(selectedContractNumber[0].value);
            return helpers.setValue(contractSelectionValues || initialValues.contractSelection);
        },
        // eslint-disable-next-line
        [contracts],
    );

    const contractOptions = contracts
        ? [...contracts.filter((contract) => contract.isActive), ...contracts.filter((contract) => !contract.isActive)]
              .map(({ productName, brandModelType, contractNumber, isActive, statusLabel }) => {
                  if (!contractNumber || !productName) return null;

                  return (
                      <Combobox.Item
                          testId={`contract-selection-item-${contractNumber}`}
                          optionValue={contractNumber}
                          optionKey={contractNumber}
                          key={contractNumber}
                      >
                          <Layout flush>
                              <Layout.Item>
                                  <b>{brandModelType}</b>
                              </Layout.Item>
                              <Layout.Item>
                                  {productName} {contractNumber}{' '}
                                  {!isActive && statusLabel && <i>{`(${statusLabel})`}</i>}
                              </Layout.Item>
                          </Layout>
                      </Combobox.Item>
                  );
              })
              .filter(Boolean)
        : [];

    const generalOptionValue = t(`contract-selection.general-value`);
    const generalOption = (
        <Combobox.Item
            testId={`contract-selection-item-general`}
            optionValue={generalOptionValue}
            optionKey={generalKey}
            key={generalKey}
        >
            <b>{generalOptionValue}</b>
        </Combobox.Item>
    );
    const options = [generalOption, ...contractOptions];

    const label = `${t('contract-selection.label')} *`;

    return (
        <FormField
            type="select"
            testId={'contract-selection'}
            id={'contract-selection'}
            errorMessage={
                meta.touched &&
                meta.error && <ErrorMessage testId={'textarea-error-message'}>{meta.error}</ErrorMessage>
            }
        >
            <Combobox testId={'custom-contract-select'} icon="semantic-expand" label={label} onChange={setValueChange}>
                {options}
            </Combobox>
        </FormField>
    );
};
