import { DefaultBusinessMarketApiError, DefaultMarketApiErrors } from '@cp-shared-5/common-utilities';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-5/frontend-storybook-extensions';
import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { ContractsWithAllValues } from '../ExampleData';
import { ContractsMock } from './contractsMock';
import { ContractDetailsWithAllValues, ContractDetailsWithMissingValues } from './ExampleData';
import { ContractWithLinks, getContractDetailsEndpoint } from 'common';

const mockOptions: MockOptions<DefaultMarketApiErrors> = {
    Success: {
        status: 200,
        responseBody: ContractDetailsWithAllValues,
        delay: 1000,
    },
    'With missing values': {
        status: 200,
        responseBody: ContractDetailsWithMissingValues,
        delay: 1000,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ContractDetailsMock = (contract: ContractWithLinks): EndpointMockDescription<DefaultMarketApiErrors> => {
    return {
        name: 'Financial Details Response',
        method: 'get',
        defaultMockOption: 'Success',
        mockOptions,
        url: getContractDetailsEndpoint(contract.contractNumber || ''),
    };
};

function mockContractDetailsResponse(): void {
    const ContractDetailsMocks = [ContractDetailsMock(ContractsWithAllValues[0])];
    setupMockResponses(CpDataApi, [ContractsMock, ...ContractDetailsMocks]);
}

export const ContractDetailsResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    mockContractDetailsResponse();
    return <div>{storyFn()}</div>;
};
