import React from 'react';

import { Faq } from 'common';
import { Faq as FaqShared, HeroImage, useAnalyticsActionTracker } from '@cp-shared-5/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';

type FaqUiProps = {
    faq?: Faq;
    isLoggedIn: boolean;
};

export const FaqUi: React.FC<FaqUiProps> = ({ faq, isLoggedIn }) => {
    const { onAction } = useAnalyticsActionTracker('onFaqQuestion');
    if (!faq) {
        return null;
    }
    const { teaser, content } = faq || {};
    const [publicContent, privateContent] = content || [];

    const teaserComponent = teaser ? (
        <HeroImage title={teaser.title} imageUrl={teaser.imageUrl} shallow={true} inverted={!!teaser.isInverted} />
    ) : null;

    const { header } = publicContent || { header: '' };

    const items = !!isLoggedIn ? privateContent.items : publicContent.items;

    let openItems = [];

    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        onAction(refs[refs.length - 1]);
    };

    const contentComponent = <FaqShared onChange={handleOnChange} header={header} items={items} descriptionText="" />;
    return (
        <>
            {teaserComponent}
            <ContentSection pageWrapSize="medium">{contentComponent}</ContentSection>
        </>
    );
};
