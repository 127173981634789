import {
    DATE_FORMAT,
    getPersonRegistrationEndpoint,
    PersonRegistrationData,
    RegistrationError,
    RegistrationResult,
} from 'common';
import { formatCpDate, WithDefaultCpIntegrationErrors } from '@cp-shared-5/common-utilities';
import { CpDataApi } from 'cp-xhr';
import { parseErrorResponse } from '@cp-shared-5/frontend-integration';
import { PersonFormProps } from '../types';

export const handleSubmit = (
    formValues: PersonFormProps,
    successCallback: (registrationResult: RegistrationResult, registrationData: PersonFormProps) => void,
    errorCallback: (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => void,
): void => {
    const { privateCustomerNumber, firstName, surname, dateOfBirth } = formValues;
    const registrationData: PersonRegistrationData = {
        customerNumber: +privateCustomerNumber,
        firstName,
        surname,
        dateOfBirth: formatCpDate(dateOfBirth, DATE_FORMAT).toCpDate(),
    };
    CpDataApi.post(getPersonRegistrationEndpoint(), registrationData)
        .then((response) => {
            const responseData: RegistrationResult = response.data;
            successCallback(responseData, formValues);
        })
        .catch((error) => {
            const errorCode = parseErrorResponse<RegistrationError>(error).code;
            const errorMessage = error.response?.data?.message;
            errorCallback(errorCode, errorMessage);
        });
};
