import React from 'react';
import { AmortizationDetails, ContractType } from 'common';
import {
    createDynamicTable,
    DynamicTableFormatOptions,
    DynamicTableSortOptions,
    TableHeaderEntry,
} from '@cp-shared-5/frontend-ui';
import { generateRow, getColumnHeadings, ToColumnValues } from './utils';
import { useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';

export type AmortizationTableInstallmentOverviewProps = {
    amortizationDetails: AmortizationDetails[];
    contractType: ContractType;
};

const DynamicTable = createDynamicTable<AmortizationDetails>();

export const AmortizationTableInstallmentOverview: React.FC<AmortizationTableInstallmentOverviewProps> = ({
    amortizationDetails,
    contractType,
}) => {
    const { t, f } = useTranslationWithFormatting('amortization-details');
    const formatOptions: DynamicTableFormatOptions = {
        auto: false,
        noScroll: true,
        colored: false,
        bordered: false,
        highlight: false,
        wide: true,
        narrow: false,
    };

    const columnHeadings: TableHeaderEntry[] = getColumnHeadings(contractType, t);

    const sortOptions: DynamicTableSortOptions<AmortizationDetails> = {
        compare: (row1: AmortizationDetails, row2: AmortizationDetails) =>
            row1.numberOfPayment !== undefined && row2.numberOfPayment !== undefined
                ? row1.numberOfPayment - row2.numberOfPayment
                : 0,
        reversed: true,
    };

    return (
        <DynamicTable
            rows={amortizationDetails}
            columnHeadings={columnHeadings}
            formatOptions={formatOptions}
            sortOptions={sortOptions}
            toColumnValues={(row: AmortizationDetails): ToColumnValues => generateRow(row, contractType, f)}
        />
    );
};
