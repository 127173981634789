import React from 'react';
import { ContractType, FinancialDetails } from 'common';
import { GeneralSection } from '../general-section/GeneralSection';
import { Hr } from '@vwfs-bronson/bronson-react';
import { FinancingSection } from '../financing-section/FinancingSection';
import { MileageSection } from '../mileage-section/MileageSection';

export const FinancialDetailsUi: React.FC<{
    contractType?: ContractType;
    financialDetails?: FinancialDetails;
    changeContractDurationAvailable?: boolean;
    paymentDateAvailable?: boolean;
    changeMileageAvailable?: boolean;
    encryptedContractNumber: string;
}> = ({
    contractType,
    financialDetails,
    encryptedContractNumber,
    changeMileageAvailable,
    paymentDateAvailable,
    changeContractDurationAvailable,
}) => {
    if (!contractType || !financialDetails || !encryptedContractNumber) {
        return null;
    }
    return (
        <>
            <GeneralSection
                details={financialDetails}
                encryptedContractNumber={encryptedContractNumber}
                changeContractDurationAvailable={changeContractDurationAvailable}
            />
            <Hr className="u-mb-none" />
            <FinancingSection
                contractType={contractType}
                details={financialDetails}
                paymentDateAvailable={paymentDateAvailable}
                encryptedContractNumber={encryptedContractNumber}
            />
            {(contractType === ContractType.BALLON_CREDIT || contractType === ContractType.OPERATIVE_LEASING) && (
                <>
                    <Hr className="u-mb-none" />
                    <MileageSection
                        contractType={contractType}
                        details={financialDetails}
                        encryptedContractNumber={encryptedContractNumber}
                        changeMileageAvailable={changeMileageAvailable}
                    />
                </>
            )}
        </>
    );
};
