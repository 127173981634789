import { ContractDetails } from 'common';
import { DefaultMarketApiErrors } from '@cp-shared-5/common-utilities';
import {
    AbstractDataState,
    hasFetchedSuccessfully,
    useGetContractBasedApiData,
} from '@cp-shared-5/frontend-integration';
import { useContract } from 'components/contracts/useContract';
import { useMemo } from 'react';

import { ContractDetailsDataSelector } from './ContractDetailsDataSelector';
import { fetchContractDetails } from './ContractDetailsSlice';

export function useContractDetails(contractNumber: string): AbstractDataState<ContractDetails, DefaultMarketApiErrors> {
    const contractState = useContract(contractNumber);
    const contractDetailsState = useGetContractBasedApiData(
        contractNumber,
        fetchContractDetails,
        ContractDetailsDataSelector,
        contractState.data?._links?.contractDetails,
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || contractDetailsState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && contractDetailsState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? contractDetailsState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: contractDetailsState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? contractDetailsState.loadingError
                : contractState.loadingError,
        }),
        [contractState, contractDetailsState],
    );
}
