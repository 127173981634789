import React from 'react';
import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { RegistrationError, RegistrationResult } from 'common';
import { WithDefaultCpIntegrationErrors } from '@cp-shared-5/common-utilities';
import {
    CleaveInput,
    InfoIcon,
    preventSubmit,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    ValidatedCheckbox,
    ValidatedInput,
} from '@cp-shared-5/frontend-ui';
import { businessIdentificationFormValidationSchema } from './validation';
import { handleSubmit } from './utils';
import { BusinessFormProps } from '../types';
import { legalNoticePath, privacyPolicyPath } from '../../../../navigation/paths';
import { useTrackerOnBeforeUnload } from '../../../../../hooks';

type BusinessIdentificationFormProps = {
    onSubmitClick: Function;
    onSuccess: (data: RegistrationResult, formData: BusinessFormProps) => void;
    onError: (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => void;
};

export const BusinessIdentificationForm: React.FC<BusinessIdentificationFormProps> = ({
    onSubmitClick,
    onSuccess,
    onError,
}) => {
    const { t } = useTranslation('registration');
    const translationPrefix = 'registration-form.business.input-labels';
    const setLastTouchedField = useTrackerOnBeforeUnload();
    const { onTyping, onSubmit: trackOnSubmit } = useAnalyticsFormTracker({
        startTyping: 'onStartTypingConfirmIdentity',
        confirm: 'onConfirmConfirmIdentiy',
    });

    const { onAction } = useAnalyticsActionTracker('onFormValidationErrorConfirmIdentity');
    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);
    const initialListErrors =
        'businessCustomerNumber, companyIdentificationNumber, companyName, confirmTermsAndConditions, confirmPrivacyPolicy';

    const onSubmit = (formValues: BusinessFormProps): void => {
        onSubmitClick();
        trackOnSubmit();
        handleSubmit(formValues, onSuccess, onError);
    };

    const labels = {
        businessCustomerNumber: {
            text: t(`${t(`${translationPrefix}.customer-number.label`)} `),
            tooltip: t(`${translationPrefix}.customer-number.tooltip`),
        },
        companyName: t(`${translationPrefix}.company-name`),
        companyIdentificationNumber: t(`${translationPrefix}.company-id`),
        confirmTermsAndConditions: {
            text: t(`${translationPrefix}.agreements.terms-and-conditions.label`),
            link: t(`${translationPrefix}.agreements.terms-and-conditions.link`),
            tooltip: t(`${translationPrefix}.agreements.terms-and-conditions.tooltip`),
        },
        confirmPrivacyPolicy: {
            text: t(`${translationPrefix}.agreements.privacy-policy.label`),
            link: t(`${translationPrefix}.agreements.privacy-policy.link`),
            tooltip: t(`${translationPrefix}.agreements.privacy-policy.tooltip`),
        },
    };

    return (
        <Layout.Item>
            <Formik
                initialValues={{
                    businessCustomerNumber: '',
                    companyName: '',
                    companyIdentificationNumber: '',
                    confirmTermsAndConditions: false,
                    confirmPrivacyPolicy: false,
                }}
                validationSchema={businessIdentificationFormValidationSchema(t)}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <Form onSubmit={preventSubmit()} onChange={() => onTyping(formik.errors, formik.touched)}>
                        <Fieldset>
                            <Fieldset.Row>
                                <CleaveInput
                                    cleaveOptions={{
                                        numericOnly: true,
                                    }}
                                    inputMode={'numeric'}
                                    label={labels.businessCustomerNumber.text}
                                    tooltip={labels.businessCustomerNumber.tooltip}
                                    name={'businessCustomerNumber'}
                                    testId={'business-customer-number'}
                                    onFocus={() => setLastTouchedField(labels.businessCustomerNumber.text)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInput
                                    label={labels.companyName}
                                    name="companyName"
                                    testId={'company-name'}
                                    onFocus={() => setLastTouchedField(labels.companyName)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInput
                                    label={labels.companyIdentificationNumber}
                                    name="companyIdentificationNumber"
                                    testId={'company-id'}
                                    onFocus={() => setLastTouchedField(labels.companyIdentificationNumber)}
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={
                                        <>
                                            {labels.confirmTermsAndConditions.text}{' '}
                                            <a href={legalNoticePath()} target="_blank" rel="noopener noreferrer">
                                                {labels.confirmTermsAndConditions.link}
                                            </a>{' '}
                                            <InfoIcon text={labels.confirmTermsAndConditions.tooltip} />
                                        </>
                                    }
                                    name="confirmTermsAndConditions"
                                    testId={'business-confirm-terms-and-conditions'}
                                    onFocus={() =>
                                        setLastTouchedField(
                                            `${labels.confirmTermsAndConditions.text} ${labels.confirmTermsAndConditions.link}`,
                                        )
                                    }
                                />
                            </Fieldset.Row>

                            <Fieldset.Row>
                                <ValidatedCheckbox
                                    label={
                                        <>
                                            {labels.confirmPrivacyPolicy.text}{' '}
                                            <a href={privacyPolicyPath()} target="_blank" rel="noopener noreferrer">
                                                {labels.confirmPrivacyPolicy.link}
                                            </a>{' '}
                                            <InfoIcon text={labels.confirmPrivacyPolicy.tooltip} />
                                        </>
                                    }
                                    name="confirmPrivacyPolicy"
                                    testId={'business-confirm-privacy-policy'}
                                    onFocus={() =>
                                        setLastTouchedField(
                                            `${labels.confirmPrivacyPolicy.text} ${labels.confirmPrivacyPolicy.link}`,
                                        )
                                    }
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <Button
                                    full={true}
                                    onClick={() => {
                                        formik.submitForm();
                                        if (!isEmpty(formik.errors) || isEmpty(formik.touched)) {
                                            const errorToString = getErrors(formik.errors).toString();
                                            if (!errorToString) {
                                                onAction(initialListErrors);
                                            } else onAction(getErrors(formik.errors));
                                        }
                                    }}
                                    testId={'submit-business-registration-button'}
                                >
                                    {t(`registration-form.button-labels.confirm`)}
                                </Button>
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </Layout.Item>
    );
};
