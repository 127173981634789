import React from 'react';
import {
    Accordion,
    ContractActionItemType,
    ContractActions,
    useAnalyticsActionTracker,
} from '@cp-shared-5/frontend-ui';
import {
    amortizationTablePath,
    earlySettlementPath,
    myDocumentsPagePath,
    paymentOverviewPath,
} from '../../../navigation/paths';
import { useTranslation } from 'react-i18next';
import { ContractWithLinks } from 'common';
import { ContractDetails } from '../../contract-details';
import {
    isPartialEarlySettlementAvailable,
    isPaymentOverviewAvilable,
    isTotalEarlySettlementAvailable,
} from 'components/contracts/early-settlement/total-early-settlement';

export const Body: React.FC<{ contract: ContractWithLinks }> = ({ contract }) => {
    const { onAction } = useAnalyticsActionTracker('onOpenContractAccordion');
    const { t } = useTranslation('contracts');

    if (!contract || !contract.contractNumber || !contract.encryptedContractNumber) {
        return null;
    }
    const { encryptedContractNumber, contractNumber } = contract;

    const getActionItems = (): ContractActionItemType[] => {
        const actionItems: ContractActionItemType[] = [
            {
                iconName: 'semantic-calendar',
                pageUrl: amortizationTablePath(encryptedContractNumber),
                label: t('contract-actions.amortization-table'),
            },
        ];
        if (isTotalEarlySettlementAvailable(contract) || isPartialEarlySettlementAvailable(contract)) {
            actionItems.push({
                iconName: 'semantic-calculator',
                pageUrl: earlySettlementPath(encryptedContractNumber),
                label: t('contract-actions.joint-early-settlement'),
            });
        }
        if (isPaymentOverviewAvilable(contract)) {
            actionItems.push({
                iconName: 'semantic-calendar',
                pageUrl: paymentOverviewPath(encryptedContractNumber),
                label: t('contract-actions.payment-overview'),
            });
        }
        if (contract.invoicesAvailable) {
            actionItems.unshift({
                iconName: 'documents',
                pageUrl: `${myDocumentsPagePath()}?filter=${contractNumber}&docType=${t('document-filter.invoice')}`,
                label: t('contract-actions.invoice-overview'),
            });
        }

        return actionItems;
    };

    const getViewChange = (currentViewName: string) => {
        switch (currentViewName) {
            case t('financial-details:headline'):
                return 'Financial details';
            case t('vehicle-details:headline'):
                return 'Vehicle details';
            case t('contract-parties:headline'):
                return 'Contract parties';
            case t('bundled-products:headline'):
                return 'Products / Services included';
        }
    };

    let openItems = [];

    const handleOnChange = (refs: string[]): void => {
        if (openItems.length > refs.length || !refs.length) {
            openItems = [...refs];
            return;
        }
        openItems = [...refs];
        const viewChange = getViewChange(refs[refs.length - 1]);
        onAction(viewChange);
    };

    return (
        <Accordion lazyRender={true} onChange={handleOnChange}>
            <div className={'u-mt-small u-mb-small'}>
                <ContractActions contractActionItems={getActionItems()} />
            </div>
            <ContractDetails contract={contract} />
        </Accordion>
    );
};
