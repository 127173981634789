import React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useUpdateMyProfile } from '../useUpdateMyProfile';
import { EditStatus } from 'common';
import { changeIbanPath } from '../../navigation/paths';
import { ConsultView } from './consult-view';
import { EditView } from './edit-view/EditView';

export type BankSectionProps = {
    iban?: string;
};

export const BankSection: React.FC<BankSectionProps> = ({ iban }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { updateMyProfile, myProfileData } = useUpdateMyProfile();

    const setLastEditStatus = (newEditStatus: EditStatus, updatedIban?: string): void => {
        updateMyProfile({
            ...myProfileData,
            iban: updatedIban ?? iban,
            lastEditStatus: { ...myProfileData?.lastEditStatus, iban: newEditStatus },
        });
    };

    const startEditing = (): void => {
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changeIbanPath());
    };

    const cancelEditing = (): void => {
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(path);
    };
    const finishEditing = (newEditStatus: EditStatus, updatedIban?: string): void => {
        setLastEditStatus(newEditStatus, updatedIban);
        history.push(path);
    };

    return (
        <Switch>
            <Route exact path={changeIbanPath()}>
                <EditView iban={iban} cancelEditing={cancelEditing} finishEditing={finishEditing} />
            </Route>
            <Route path={path}>
                <ConsultView
                    iban={iban}
                    startEditing={startEditing}
                    lastEditStatus={myProfileData?.lastEditStatus?.iban}
                />
            </Route>
        </Switch>
    );
};
