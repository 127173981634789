import { ProofOfCarOwnership, ProofOfCarOwnershipError, proofOfCarOwnershipValidator } from 'common';
import * as Yup from 'yup';
import { areAllFilesValid } from '../file-upload/helpers';
import { TFunction } from 'i18next';

export const getValidationSchema = (t: TFunction): Yup.SchemaOf<Omit<ProofOfCarOwnership, 'files'>> => {
    const messages: Omit<ProofOfCarOwnershipError, 'files'> = {
        licensePlate: {
            required: t('license-plate.validation.required'),
            format: t('license-plate.validation.format'),
        },
        numberOfTechnicalDocument: {
            required: t('number-of-technical-document.validation.required'),
            format: t('number-of-technical-document.validation.format'),
        },
        dateOfFirstRegistrationInSk: {
            required: t('date-of-first-registration-in-sk.validation.required'),
            valid: t('date-of-first-registration-in-sk.validation.valid'),
        },
        yearOfProduction: {
            required: t('year-of-production.validation.required'),
        },
    };

    return proofOfCarOwnershipValidator(messages).shape({
        frontOfDocument: Yup.array()
            .min(1, t('file-upload:mandatory-error'))
            .max(1, t('file-upload:max-one-file-error'))
            .test('files', '', (files) => areAllFilesValid(files as File[])),
        backOfDocument: Yup.array()
            .min(0)
            .max(1, t('file-upload:max-one-file-error'))
            .test('files', '', (files) => areAllFilesValid(files as File[])),
    });
};
