import {
    BundledProducts,
    CarInsurance,
    OtherInsurance,
    ServiceAndMaintenance,
    BundledProductType,
    BundledProductStatusCode,
    ServiceAndMaintenanceProductType,
} from 'common';
import { formatCpDate } from '@cp-shared-5/common-utilities';

export const CarInsuranceWithAllValues: CarInsurance = {
    productType: BundledProductType.MOTOR_INSURANCE,
    productName: 'Povinné zmluvné poistenie',
    insurer: 'Allianz- Slovenská poisťovňa a.s.',
    policyNumber: '1987654321/01234',
    statusCode: BundledProductStatusCode.A,
    status: 'Je súčasťou splátok',
    startDate: formatCpDate('2015-11-11T09:44:57.299').toCpDate(),
    endDate: formatCpDate('2021-11-11T09:44:57.299').toCpDate(),
    endLabel: 'Doba neurčitá',
    monthlyAmount: '34,50 €',
    participationRateOfKaskoInsuranceV: '5,00%',
    participationRateOfKaskoInsuranceN: '63,54 €',
};

export const InactiveCarInsuranceWithAllValues: CarInsurance = {
    ...CarInsuranceWithAllValues,
    statusCode: BundledProductStatusCode.N,
};

export const CarInsuranceWithAccidentInsurance: CarInsurance = {
    ...CarInsuranceWithAllValues,
    productType: BundledProductType.ACCIDENT_INSURANCE,
};

export const OtherInsuranceWithAllValues: OtherInsurance = {
    productType: BundledProductType.GAP_INSURANCE,
    productName: 'Poistenie schopnosti splácať',
    insurer: 'Allianz- Slovenská poisťovňa a.s.',
    statusCode: BundledProductStatusCode.A,
    status: 'nie je súčasťou splátok',
    monthlyAmount: '34,50 €',
};

export const InactiveOtherInsuranceWithAllValues: CarInsurance = {
    ...OtherInsuranceWithAllValues,
    statusCode: BundledProductStatusCode.N,
};

export const ServiceAndMaintenanceWithAllValues: ServiceAndMaintenance = {
    productType: ServiceAndMaintenanceProductType.S,
    productName: 'Povinné zmluvné poistenie',
    statusCode: BundledProductStatusCode.A,
    status: 'Je súčasťou splátok',
    startDate: formatCpDate('2015-11-11T09:44:57.299').toCpDate(),
    endDate: formatCpDate('2021-11-11T09:44:57.299').toCpDate(),
    servicePackage: 'abc',
    tireClass: 'Leto: 205/55R16 Stredná trieda, Zima: 205/55R16 Stredná trieda',
};

export const ServiceAndMaintenanceWithTireClass: ServiceAndMaintenance = {
    ...ServiceAndMaintenanceWithAllValues,
    productType: ServiceAndMaintenanceProductType.Q,
};

export const InactiveServiceAndMaintenanceWithAllValues: ServiceAndMaintenance = {
    ...ServiceAndMaintenanceWithAllValues,
    statusCode: BundledProductStatusCode.N,
};

export const CarInsuranceWithMissingValues: CarInsurance = {
    productType: BundledProductType.MOTOR_INSURANCE,
    productName: 'Povinné zmluvné poistenie',
    policyNumber: '1987654321/01234',
    statusCode: BundledProductStatusCode.A,
    startDate: formatCpDate('2015-11-11T09:44:57.299').toCpDate(),
    endLabel: 'Doba neurčitá',
    monthlyAmount: '34,50 €',
    participationRateOfKaskoInsuranceN: '63,54 €',
};

export const InactiveCarInsuranceWithMissingValues: CarInsurance = {
    ...CarInsuranceWithMissingValues,
    statusCode: BundledProductStatusCode.N,
};

export const OtherInsuranceWithMissingValues: OtherInsurance = {
    productType: BundledProductType.GAP_INSURANCE,
    productName: 'Poistenie schopnosti splácať',
    insurer: 'Allianz- Slovenská poisťovňa a.s.',
    statusCode: BundledProductStatusCode.A,
    monthlyAmount: '34,50 €',
};

export const InactiveOtherInsuranceWithMissingValues: CarInsurance = {
    ...OtherInsuranceWithMissingValues,
    statusCode: BundledProductStatusCode.N,
};

export const ServiceAndMaintenanceWithMissingValues: ServiceAndMaintenance = {
    productType: ServiceAndMaintenanceProductType.S,
    productName: 'Povinné zmluvné poistenie',
    statusCode: BundledProductStatusCode.A,
    status: 'Je súčasťou splátok',
    startDate: formatCpDate('2015-11-11T09:44:57.299').toCpDate(),
};

export const InactiveServiceAndMaintenanceWithMissingValues: ServiceAndMaintenance = {
    ...ServiceAndMaintenanceWithMissingValues,
    statusCode: BundledProductStatusCode.N,
};

export const BundledProductsWithAllValues: BundledProducts = {
    carInsurance: [CarInsuranceWithAllValues, InactiveCarInsuranceWithAllValues],
    otherInsurance: OtherInsuranceWithAllValues,
    serviceAndMaintenance: [ServiceAndMaintenanceWithAllValues, InactiveServiceAndMaintenanceWithAllValues],
};

export const BundledProductsWithMissingValues: BundledProducts = {
    carInsurance: [CarInsuranceWithMissingValues, InactiveCarInsuranceWithMissingValues],
    otherInsurance: OtherInsuranceWithMissingValues,
    serviceAndMaintenance: [ServiceAndMaintenanceWithMissingValues, InactiveServiceAndMaintenanceWithMissingValues],
};

export const BundledProductsWithNoActiveProducts: BundledProducts = {
    carInsurance: [InactiveCarInsuranceWithAllValues],
    otherInsurance: InactiveOtherInsuranceWithAllValues,
    serviceAndMaintenance: [InactiveServiceAndMaintenanceWithAllValues],
};

export const BundledProductsWithMissingSections: BundledProducts = {
    carInsurance: [CarInsuranceWithAllValues],
};

export const BundledProductsWithError: BundledProducts = {
    carInsurance: undefined,
    otherInsurance: undefined,
    serviceAndMaintenance: undefined,
};
