import { VehicleDetails } from 'common';
import { formatCpDate } from '@cp-shared-5/common-utilities';

export const VehicleDetailsWithAllValues: VehicleDetails = {
    brandModelType: 'Volkswagen Passat Saloon 1.6TDI 105BHP CL',
    licensePlate: '301 - D - 12345',
    vin: 'WAUZZZ4G1FN076523',
    registrationDate: formatCpDate('2015-11-11T09:44:57.299').toCpDate(),
};

export const VehicleDetailsWithMissingBrandModelType: VehicleDetails = {
    licensePlate: '201 - D - 12345',
    vin: 'WAUZZZ4G1FN076523',
    registrationDate: formatCpDate('2015-11-11T09:44:57.299').toCpDate(),
};

export const VehicleDetailsWithMissingValues: VehicleDetails = {
    brandModelType: 'Volkswagen Passat Passat Saloon 1.6TDI 105BHP CL',
};

export const VehicleDetailsWithError: VehicleDetails = {};
