import { uploadFilesDirectToMediaStorage } from '@cp-shared-5/frontend-integration';
import { UiBlockingSpinner, ValidatedInput } from '@cp-shared-5/frontend-ui';
import { EditStatus, FileInfo, getUpdateLicensePlateEndpoint, UpdateLicensePlate } from 'common';
import { Button, ButtonContainer, Fieldset, Heading } from '@vwfs-bronson/bronson-react';
import { FileUpload } from 'components/file-upload';
import { CpDataApi } from 'cp-xhr';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SummaryView } from './summary-view';
import { validationSchema } from './validationSchema';

type EditViewProps = {
    encryptedContractNumber: string;
    finishEditing: (lastEditStatus: EditStatus) => void;
};

type InitialValues = {
    newLicensePlate: string;
    technicalDocumentNumber: string;
    frontOfDocument: File[];
    backOfDocument: File[];
};

export const DOCTYPE_ID = 126;

export const EditView: React.FC<EditViewProps> = ({ finishEditing, encryptedContractNumber }) => {
    const { t } = useTranslation('vehicle-details');
    const translationPrefix = 'edit-view';
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showConfirmationView, setShowConfirmationView] = useState<boolean>(false);
    const myRef = useRef<HTMLDivElement>(null);

    const executeScroll = () =>
        myRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });

    const onSubmit = () => {
        setShowConfirmationView(true);
        executeScroll();
    };

    const updateLicensePlate = (values: InitialValues) => {
        setIsSubmitting(true);

        const { newLicensePlate, technicalDocumentNumber, frontOfDocument, backOfDocument } = values;

        const frontOfDocumentFile = uploadFilesDirectToMediaStorage(frontOfDocument, CpDataApi);
        const backOfDocumentFile = uploadFilesDirectToMediaStorage(backOfDocument, CpDataApi);

        const doAfterSuccessfulUpload = (frontOfDocumentId: string[], backOfDocumentId: string[]): void => {
            const body: UpdateLicensePlate = {
                newLicensePlate,
                technicalDocumentNumber,
                files: [
                    ...frontOfDocumentId.map(
                        (fileId: string): FileInfo => ({
                            docTypeId: DOCTYPE_ID,
                            fileId,
                        }),
                    ),
                    ...backOfDocumentId.map(
                        (fileId: string): FileInfo => ({
                            docTypeId: DOCTYPE_ID,
                            fileId,
                        }),
                    ),
                ],
            };
            CpDataApi.put(getUpdateLicensePlateEndpoint(encryptedContractNumber), body)
                .then(() => {
                    finishEditing(EditStatus.SUCCESS);
                })
                .catch(() => {
                    finishEditing(EditStatus.ERROR);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        };

        const doAfterUploadError = (): void => {
            setIsSubmitting(false);
            finishEditing(EditStatus.ERROR);
        };

        Promise.all([frontOfDocumentFile, backOfDocumentFile])
            .then(([frontOfDocumentId, backOfDocumentId]) =>
                doAfterSuccessfulUpload(frontOfDocumentId, backOfDocumentId),
            )
            .catch(() => {
                doAfterUploadError();
            });
    };

    const initialValues: InitialValues = {
        newLicensePlate: '',
        technicalDocumentNumber: '',
        frontOfDocument: [],
        backOfDocument: [],
    };

    return (
        <>
            <Heading className="u-pt" level={'6'}>
                <div ref={myRef}>{t(`${translationPrefix}.title`)}</div>
            </Heading>
            <UiBlockingSpinner isBlocking={isSubmitting}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema(t)}
                    onSubmit={onSubmit}
                    validateOnBlur={true}
                >
                    {({ handleSubmit, values }) => (
                        <>
                            {!showConfirmationView ? (
                                <>
                                    <p>{t(`${translationPrefix}.subtitle`)}</p>
                                    <p dangerouslySetInnerHTML={{ __html: t(`${translationPrefix}.description`) }} />
                                    <Form onSubmit={(e) => e.preventDefault()} data-testid="license-plate-form">
                                        <Fieldset>
                                            <Fieldset.Row>
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.license-plate.label`)}
                                                    tooltip={t(`${translationPrefix}.license-plate.tooltip`)}
                                                    name="newLicensePlate"
                                                    testId="new-license-plate"
                                                    isMandatory
                                                />
                                            </Fieldset.Row>
                                            <Fieldset.Row>
                                                <ValidatedInput
                                                    label={t(`${translationPrefix}.technical-document-number.label`)}
                                                    tooltip={t(
                                                        `${translationPrefix}.technical-document-number.tooltip`,
                                                    )}
                                                    name="technicalDocumentNumber"
                                                    testId="technical-document-number"
                                                    isMandatory
                                                />
                                            </Fieldset.Row>
                                            <Fieldset.Row>
                                                <FileUpload
                                                    name="frontOfDocument"
                                                    label={t(`${translationPrefix}.document-front-side.label`)}
                                                    isMandatory
                                                />
                                            </Fieldset.Row>
                                            <Fieldset.Row>
                                                <FileUpload
                                                    name="backOfDocument"
                                                    label={t(`${translationPrefix}.document-back-side.label`)}
                                                    tooltip={t(`${translationPrefix}.document-back-side.tooltip`)}
                                                    isMandatory={false}
                                                />
                                            </Fieldset.Row>
                                            <Fieldset.Row>
                                                <ButtonContainer className={'u-mt'} center>
                                                    <Button
                                                        secondary
                                                        onClick={(): void => {
                                                            finishEditing(EditStatus.NOT_PERFORMED);
                                                        }}
                                                        testId="cancel"
                                                    >
                                                        {t('translation:editable-section-nav.cancel')}
                                                    </Button>
                                                    <Button onClick={handleSubmit} testId="submit" type="submit">
                                                        {t('translation:editable-section-nav.change')}
                                                    </Button>
                                                </ButtonContainer>
                                            </Fieldset.Row>
                                        </Fieldset>
                                    </Form>
                                </>
                            ) : (
                                <SummaryView
                                    newLicensePlate={values.newLicensePlate}
                                    technicalDocumentNumber={values.technicalDocumentNumber}
                                    onClose={() => {
                                        setShowConfirmationView(false);
                                    }}
                                    onConfirm={() => {
                                        updateLicensePlate(values);
                                    }}
                                />
                            )}
                        </>
                    )}
                </Formik>
            </UiBlockingSpinner>
        </>
    );
};
