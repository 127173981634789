import React, { useState } from 'react';
import { getEarlySettlementPdfDownloadEndpoint } from 'common';
import { formatCpDate } from '@cp-shared-5/common-utilities';
import base64ToBlob from 'b64-to-blob';
import { saveAs as downloadFileAs } from 'file-saver';
import { CpDataApi } from 'cp-xhr';
import { Link, Modal } from '@vwfs-bronson/bronson-react';
import { Spinner } from '@cp-shared-5/frontend-ui';
import { useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';

interface Props {
    encryptedContractNumber: string;
    contractNumber: string;
}

export const EarlySettlementDownload: React.FC<Props> = ({ encryptedContractNumber, contractNumber }) => {
    const [isPdfDownloading, setIsPdfDownloading] = useState<boolean>(false);
    const [downloadError, setDownloadError] = useState<boolean>(false);

    const { t } = useTranslationWithFormatting('total-early-settlement');

    const downloadAsPdf = (): void => {
        setIsPdfDownloading(true);
        CpDataApi.get(getEarlySettlementPdfDownloadEndpoint(encryptedContractNumber))
            .then((response) => {
                const fileContent = response.data.documentContent;
                const pdfContentType = 'application/pdf';
                const pdfBlob = base64ToBlob(fileContent, pdfContentType);
                const filename = `${t('table.filename')} ${contractNumber}_${formatCpDate().format('DDMMYYYY')}.pdf`;
                downloadFileAs(pdfBlob, filename);
            })
            .catch(() => {
                setDownloadError(true);
            })
            .finally(() => {
                setIsPdfDownloading(false);
            });
    };
    return (
        <>
            {isPdfDownloading ? (
                <Spinner small center={false} className="u-ml-small c-link" />
            ) : (
                <Link
                    onClick={downloadAsPdf}
                    className="u-ml-small"
                    testId={'download-pdf-link'}
                    normalLink
                    icon="download"
                >
                    {t('table.download.link.download-as-pdf')}
                </Link>
            )}
            <Modal
                shown={!!downloadError}
                title={t('errors.modal.title')}
                onClose={(): void => setDownloadError(false)}
                status="error"
                testId={'download-failure-modal'}
            >
                {t('errors.modal.text')}
            </Modal>
        </>
    );
};
