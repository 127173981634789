import { DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactDetails, EditStatus } from 'common';
import { Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';
import { ContactList } from './ContactList';
import { isEmpty } from 'lodash';
import { NoConnectionNotification } from '../../../notifications/no-connection/NoConnectionNotification';
import { NotificationForLastEditStatus } from './NotificationForLastEditStatus';

export type ConsultViewProps = {
    contactDetails?: ContactDetails;
    startEditing: () => void;
    lastEditStatus: EditStatus | undefined;
};

export const ConsultView: React.FC<ConsultViewProps> = ({
    contactDetails,
    startEditing,
    lastEditStatus = EditStatus.NOT_PERFORMED,
}) => {
    const { t } = useTranslation('my-profile-contact-details');

    return (
        <DataOverview
            title={t('consult-view.title')}
            buttonLabel={t('translation:editable-section-nav.start')}
            buttonProps={{ onClick: startEditing, testId: 'editButton' }}
        >
            {!contactDetails || isEmpty(contactDetails) ? (
                <NoConnectionNotification testId="identification-no-connection-error" />
            ) : (
                <>
                    <Notification
                        status={NotificationStatus.info}
                        text={t('consult-view.info-alert')}
                        className={`u-mb`}
                    />
                    <ContactList contactDetails={contactDetails} />
                    <NotificationForLastEditStatus lastEditStatus={lastEditStatus} />
                </>
            )}
        </DataOverview>
    );
};
