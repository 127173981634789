import React, { useState } from 'react';
import {
    Button,
    ButtonContainer,
    DataOverview,
    Fieldset,
    Form,
    Layout,
    OrderedList,
} from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import {
    BusinessCustomerData,
    ChangeCompanyIdentificationRequest,
    EditStatus,
    FileInfo,
    getCompanyIdentificationEndpoint,
    Identification,
} from 'common';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    UiBlockingSpinner,
    ValidatedInput,
} from '@cp-shared-5/frontend-ui';
import { Formik } from 'formik';
import { validationSchema } from './validationSchema';
import { CpDataApi } from '../../../../../cp-xhr';
import { FileUpload } from '../../../../file-upload';
import { SummaryView } from '../summary-view/SummaryView';
import { uploadFilesDirectToMediaStorage } from '@cp-shared-5/frontend-integration';
import { DOCTYPE_ID_BUSINESS, DOCTYPE_VAT } from '../../../addresses-section/utils';
import { FormValues } from './initialValues';
import { isEmpty } from 'lodash';
import { getErrors, IDENTIFICATION_SECTION, useOnEditIdentificationActionTrackers } from '../trackingUtils';

export type CompanyFormProps = {
    identification: Identification;
    cancelEditing: () => void;
    finishEditing: (newEditStatus: EditStatus, updatedIdentification?: BusinessCustomerData) => void;
};

export const CompanyForm: React.FC<CompanyFormProps> = ({ identification, cancelEditing, finishEditing }) => {
    const { t } = useTranslation('my-profile');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const translationPrefix = 'identification.edit-view.business';

    const { onTyping, onValidationError, onShowConfirmation, onConfirmationCancel } =
        useOnEditIdentificationActionTrackers();

    const getInitialErrors = (values: { [k: string]: string }): string => {
        const errorsList = ['companyName', 'vatNumber'].filter((element: string): boolean => !values[element]);
        return !errorsList.length ? 'companyName, vatNumber' : errorsList[0];
    };

    const { companyName = '', vatNumber = '', companyNumber } = identification.data as BusinessCustomerData;

    const initialValues: FormValues = {
        companyName,
        vatNumber,
        companyNameFiles: [],
        vatNumberFiles: [],
    };

    const onSubmit = (): void => {
        onShowConfirmation(IDENTIFICATION_SECTION);
        setShowConfirmation(true);
    };

    const getDescription = (field: 'company' | 'vat'): React.ReactNode => {
        const suffix = `${field === 'company' ? 'company-name' : 'vat-number'}.description`;
        const translateItem = (key: string): string => {
            return t(`${translationPrefix}.${suffix}.${key}`);
        };
        const keys = ['step-1', 'step-2', 'step-3', 'step-4'];
        return (
            <OrderedList>
                {keys.map(
                    (key: string): React.ReactNode => (
                        <OrderedList.Item className={'u-mb-xsmall'} key={key}>
                            {translateItem(key)}
                        </OrderedList.Item>
                    ),
                )}
            </OrderedList>
        );
    };

    const onConfirm = (values: FormValues): void => {
        setIsSubmitting(true);
        setShowConfirmation(false);
        const { companyName, vatNumber, companyNameFiles, vatNumberFiles } = values;

        const companyNameFilesPromise = uploadFilesDirectToMediaStorage(companyNameFiles, CpDataApi);
        const vatNumberFilesPromise = uploadFilesDirectToMediaStorage(vatNumberFiles, CpDataApi);

        const doAfterSuccessfulUpload = (companyNameFilesId: string[], vatNumberFilesId: string[]): void => {
            const body: ChangeCompanyIdentificationRequest = {
                companyName,
                vatNumber: vatNumber || '',
                files: [
                    ...companyNameFilesId.map((id: string): FileInfo => {
                        return {
                            docTypeId: DOCTYPE_ID_BUSINESS,
                            fileId: id,
                        };
                    }),
                    ...vatNumberFilesId.map((id: string): FileInfo => {
                        return {
                            docTypeId: DOCTYPE_VAT,
                            fileId: id,
                        };
                    }),
                ],
            };

            CpDataApi.put(getCompanyIdentificationEndpoint(), body)
                .then(() => {
                    finishEditing(EditStatus.SUCCESS, { companyName, vatNumber, companyNumber });
                })
                .catch(() => {
                    finishEditing(EditStatus.ERROR);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        };

        Promise.all([companyNameFilesPromise, vatNumberFilesPromise])
            .then(([companyNameFilesId, vatNumberFilesId]) =>
                doAfterSuccessfulUpload(companyNameFilesId, vatNumberFilesId),
            )
            .catch(() => {
                finishEditing(EditStatus.ERROR);
                setIsSubmitting(false);
            });
    };

    const onClose = (): void => {
        onConfirmationCancel(IDENTIFICATION_SECTION);
        setShowConfirmation(false);
    };

    const clearFileUpload = (
        formFieldName: string,
        setFieldValue: (field: string, value: File[], shouldValidate?: boolean) => void,
    ): void => {
        setFieldValue(formFieldName, []);
    };

    return (
        <DataOverview title={t(`${translationPrefix}.title`)}>
            <UiBlockingSpinner isBlocking={isSubmitting}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema(t, companyName, vatNumber)}
                    onSubmit={onSubmit}
                >
                    {({ submitForm, values, setFieldValue, errors, touched }): React.ReactNode => (
                        <Form
                            onSubmit={preventSubmit()}
                            data-testid="edit-form"
                            onChange={(): void => onTyping(errors, touched)}
                        >
                            {showConfirmation ? (
                                <SummaryView
                                    customerType={'Business'}
                                    newData={values}
                                    onConfirm={(): void => onConfirm(values)}
                                    onClose={onClose}
                                />
                            ) : (
                                <>
                                    <Notification
                                        status={NotificationStatus.info}
                                        text={t(`${translationPrefix}.info`)}
                                        className={`u-mb`}
                                    />
                                    <Fieldset>
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item default="1/1" s="1/1">
                                                    {getDescription('company')}
                                                </Layout.Item>
                                            </Layout>
                                            <Layout>
                                                <Layout.Item default="1/2" s="1/1">
                                                    <ValidatedInput
                                                        label={t(`${translationPrefix}.company-name.label`)}
                                                        name="companyName"
                                                        testId="company-name"
                                                        type="text"
                                                        handleChange={(
                                                            evt: React.ChangeEvent<{ value: string }>,
                                                        ): void => {
                                                            if (evt.target.value === companyName) {
                                                                clearFileUpload('companyNameFiles', setFieldValue);
                                                            }
                                                        }}
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                        {companyName !== values.companyName && (
                                            <>
                                                <span>{t(`${translationPrefix}.company-name.info`)}</span>
                                                <FileUpload name={'companyNameFiles'} />
                                            </>
                                        )}
                                        <Fieldset.Row>
                                            <Layout>
                                                <Layout.Item default="1/1" s="1/1">
                                                    {getDescription('vat')}
                                                </Layout.Item>
                                            </Layout>
                                            <Layout>
                                                <Layout.Item default="1/2" s="1/1">
                                                    <ValidatedInput
                                                        label={t(`${translationPrefix}.vat-number.label`)}
                                                        name="vatNumber"
                                                        testId="vat-number"
                                                        type="text"
                                                        handleChange={(
                                                            evt: React.ChangeEvent<{ value: string }>,
                                                        ): void => {
                                                            if (evt.target.value === vatNumber) {
                                                                clearFileUpload('vatNumberFiles', setFieldValue);
                                                            }
                                                        }}
                                                    />
                                                </Layout.Item>
                                            </Layout>
                                        </Fieldset.Row>
                                        {values.vatNumber !== '' && vatNumber !== values.vatNumber && (
                                            <>
                                                <span>{t(`${translationPrefix}.vat-number.info`)}</span>
                                                <FileUpload name={'vatNumberFiles'} />
                                            </>
                                        )}
                                        <Fieldset.Row>
                                            <ButtonContainer center>
                                                <Button
                                                    secondary
                                                    onClick={(): void => {
                                                        cancelEditing();
                                                    }}
                                                    testId="cancel-button"
                                                    type="button"
                                                >
                                                    {t(`translation:editable-section-nav.cancel`)}
                                                </Button>
                                                <Button
                                                    onClick={(): void => {
                                                        submitForm();
                                                        const initialErrors = getInitialErrors({
                                                            companyName: values.companyName,
                                                            vatNumber: values.vatNumber || '',
                                                        });
                                                        if (!isEmpty(errors)) {
                                                            const errorsList = getErrors(errors);
                                                            onValidationError(errorsList);
                                                        } else if (isEmpty(errors) && isEmpty(touched)) {
                                                            onValidationError(initialErrors);
                                                        }
                                                    }}
                                                    testId="submit-button"
                                                    type="submit"
                                                >
                                                    {t(`translation:editable-section-nav.change`)}
                                                </Button>
                                            </ButtonContainer>
                                        </Fieldset.Row>
                                    </Fieldset>
                                </>
                            )}
                        </Form>
                    )}
                </Formik>
            </UiBlockingSpinner>
        </DataOverview>
    );
};
