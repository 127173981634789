import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { getValue } from 'common';

export type PartialPaymentValues = {
    paymentAmount: string;
};

export const initialValues = {
    paymentAmount: '',
};

export const validationSchema = (t: TFunction, maxValue: number): Yup.AnySchema => {
    const translationPrefix = 'overview.partial-payment-section.errors';
    const minValueMessage = t(`${translationPrefix}.min-value`);
    const maxValueMessage = t(`${translationPrefix}.max-value`);
    return Yup.object().shape({
        paymentAmount: Yup.string()
            .test('min', minValueMessage, (value?: string): boolean => {
                if (!value) return true;
                return (getValue(value) || 0) > 0;
            })
            .test('max', maxValueMessage, (value?: string): boolean => {
                if (!value) return true;
                return (getValue(value) || 0) <= maxValue;
            }),
    });
};
