import {
    buildContract,
    ContractStatus,
    ContractType,
    ContractWithLinks,
    getAmortizationDetailsEndpoint,
    getBundledProductsEndpoint,
    getContractDetailsEndpoint,
    getEarlySettlementEndpoint,
    getPaymentDetailsEndpoint,
    getPaymentOverviewDetailsEndpoint,
} from 'common';

export const contractNumber = '0000123456789101';

export const ConsumerCreditWithAllValuesActive: ContractWithLinks = {
    invoicesAvailable: true,
    isActive: true,
    contractNumber,
    encryptedContractNumber: 'ASXDFSHEI121',
    endDate: '2055-11-11',
    nextPaymentDate: '2022-04-11',
    nextPaymentAmount: 1350.25,
    outstandingBalance: 1000.5,
    contractType: ContractType.CONSUMER_CREDIT,
    productName: 'Consumer Credit',
    status: ContractStatus.P,
    brandModelType: 'Volkswagen Golf GTI (1.9TDI)',
    licensePlate: '201-MH-345678',
    vin: 'VW1234567890ACC',
    encryptedVin: 'encrypted-VW1234567890ACC',
    _links: {
        contractDetails: getContractDetailsEndpoint(contractNumber),
        bundledProducts: getBundledProductsEndpoint(contractNumber),
        amortizationDetails: getAmortizationDetailsEndpoint(contractNumber),
        paymentDetails: getPaymentDetailsEndpoint(contractNumber),
        earlySettlement: getEarlySettlementEndpoint(contractNumber),
        paymentOverview: getPaymentOverviewDetailsEndpoint(contractNumber),
    },
    paymentOverviewAvailable: true,
};

export const ConsumerCreditWithAllValuesInactive: ContractWithLinks = {
    ...ConsumerCreditWithAllValuesActive,
    vin: 'VW1234567890ICC',
    encryptedVin: 'encrypted-VW1234567890ICC',
    contractNumber: '0000123456789102',
    encryptedContractNumber: 'ASXDFSHEI122',
    isActive: false,
    status: ContractStatus.R,
};

export const ConsumerCreditWithMissingContractNumber: ContractWithLinks = {
    invoicesAvailable: true,
    isActive: true,
    endDate: '2055-11-11',
    encryptedContractNumber: 'ASXDFSHEI121',
    nextPaymentDate: '2022-04-11',
    nextPaymentAmount: 1350.25,
    outstandingBalance: 1000.5,
    contractType: ContractType.CONSUMER_CREDIT,
    productName: 'Consumer Credit',
    status: ContractStatus.P,
    brandModelType: 'Volkswagen Golf GTI (1.9TDI)',
    licensePlate: '201-MH-345678',
    vin: 'VW1234567890ACC',
    encryptedVin: 'encrypted-VW1234567890ACC',
    _links: {},
};

export const ConsumerCreditWithMissingValues: ContractWithLinks = {
    ...ConsumerCreditWithMissingContractNumber,
    isActive: false,
    vin: 'VW1234567890ICC',
    encryptedVin: 'encrypted-VW1234567890ICC',
    status: ContractStatus.R,
};

export const ConsumerCreditWithOverpaymentAlert: ContractWithLinks = {
    ...ConsumerCreditWithAllValuesActive,
    contractNumber: '0000123456789111',
    encryptedContractNumber: 'XYZ111',
    overpaymentAlert: {
        headline: 'Overpayment alert headline',
        description: 'Overpayment alert description',
    },
};

export const ConsumerCreditWithCarOwnershipAlert: ContractWithLinks = {
    ...ConsumerCreditWithAllValuesActive,
    contractNumber: '0000123456789112',
    encryptedContractNumber: 'ZYX112',
    carOwnershipAlert: {
        headline: 'Car Ownership alert headline',
        description: 'Car Ownership alert description',
    },
};

export const ConsumerCreditWithManageInsurancePoliciesAlert: ContractWithLinks = {
    ...ConsumerCreditWithAllValuesActive,
    contractNumber: '0000123456789113',
    encryptedContractNumber: 'ZYX113',
    hpAlert: [
        {
            headline: 'Manage Insurance Policies alert headline',
            description: 'Manage Insurance Policies alert description',
            docExpected: true,
        },
        {
            headline: 'Manage Insurance Policies alert headline',
            description: 'Manage Insurance Policies alert description',
            docExpected: false,
        },
    ],
};

export const AutoCreditWithAllValuesActive: ContractWithLinks = {
    invoicesAvailable: true,
    isActive: true,
    contractNumber: '0000123456789103',
    encryptedContractNumber: 'ASXDFSHEI123',
    endDate: '2055-11-11',
    nextPaymentDate: '2022-04-11',
    nextPaymentAmount: 1350.25,
    outstandingBalance: 1000.5,
    contractType: ContractType.AUTO_CREDIT,
    productName: 'Auto Credit',
    status: ContractStatus.P,
    brandModelType: 'Audi A4',
    licensePlate: '201-MH-345678',
    vin: 'AUDI1234567890AAC',
    encryptedVin: 'encrypted-AUDI1234567890AAC',
    partialEarlySettlementAvailable: true,
    _links: {
        contractDetails: getContractDetailsEndpoint(contractNumber),
        bundledProducts: getBundledProductsEndpoint(contractNumber),
        amortizationDetails: getAmortizationDetailsEndpoint(contractNumber),
        paymentDetails: getPaymentDetailsEndpoint(contractNumber),
        earlySettlement: getEarlySettlementEndpoint(contractNumber),
    },
};

export const AutoCreditWithAllValuesInactive: ContractWithLinks = {
    ...AutoCreditWithAllValuesActive,
    contractNumber: '0000123456789104',
    encryptedContractNumber: 'ASXDFSHEI124',
    isActive: false,
    partialEarlySettlementAvailable: false,
    status: ContractStatus.R,
};

export const FinancialLeasingWithAllValuesActive: ContractWithLinks = {
    invoicesAvailable: true,
    isActive: true,
    contractNumber: '0000123456789105',
    encryptedContractNumber: 'ASXDFSHEI125',
    endDate: '2055-11-11',
    nextPaymentDate: '2022-04-11',
    nextPaymentAmount: 1350.25,
    lastPaymentDate: '2022-03-11',
    contractType: ContractType.FINANCIAL_LEASING,
    productName: 'Financial Leasing',
    status: ContractStatus.P,
    brandModelType: 'Volkswagen Golf GTI (1.9TDI)',
    licensePlate: '201-MH-345678',
    vin: 'VW1234567890AFL',
    encryptedVin: 'encrypted-VW1234567890AFL',
    _links: {
        contractDetails: getContractDetailsEndpoint(contractNumber),
        bundledProducts: getBundledProductsEndpoint(contractNumber),
        amortizationDetails: getAmortizationDetailsEndpoint(contractNumber),
        paymentDetails: getPaymentDetailsEndpoint(contractNumber),
        earlySettlement: getEarlySettlementEndpoint(contractNumber),
    },
};

export const FinancialLeasingWithAllValuesInactive: ContractWithLinks = {
    ...FinancialLeasingWithAllValuesActive,
    vin: 'VW1234567890IFL',
    encryptedVin: 'encrypted-VW1234567890IFL',
    contractNumber: '0000123456789106',
    encryptedContractNumber: 'ASXDFSHEI126',
    isActive: false,
    status: ContractStatus.R,
};

export const OperativeLeasingWithAllValuesActive: ContractWithLinks = {
    invoicesAvailable: true,
    isActive: true,
    contractNumber: '0000123456789107',
    encryptedContractNumber: 'ASXDFSHEI127',
    endDate: '2055-11-11',
    nextPaymentDate: '2022-04-11',
    nextPaymentAmount: 1350.25,
    lastPaymentDate: '2022-03-11',
    contractType: ContractType.OPERATIVE_LEASING,
    productName: 'Operative Leasing',
    status: ContractStatus.P,
    brandModelType: 'Seat Ibiza',
    licensePlate: '201-MH-345678',
    vin: 'SEAT1234567890AOL',
    encryptedVin: 'encrypted-SEAT1234567890AOL',
    _links: {
        contractDetails: getContractDetailsEndpoint(contractNumber),
        bundledProducts: getBundledProductsEndpoint(contractNumber),
        amortizationDetails: getAmortizationDetailsEndpoint(contractNumber),
        paymentDetails: getPaymentDetailsEndpoint(contractNumber),
        earlySettlement: getEarlySettlementEndpoint(contractNumber),
    },
};

export const OperativeLeasingWithAllValuesInactive: ContractWithLinks = {
    ...OperativeLeasingWithAllValuesActive,
    vin: 'SEAT1234567890IOL',
    encryptedVin: 'encrypted-SEAT1234567890IOL',
    contractNumber: '0000123456789108',
    encryptedContractNumber: 'ASXDFSHEI128',
    isActive: false,
    status: ContractStatus.R,
};

export const BallonCreditWithAllValuesActive: ContractWithLinks = {
    invoicesAvailable: true,
    isActive: true,
    contractNumber: '0000123456789109',
    encryptedContractNumber: 'ASXDFSHEI129',
    endDate: '2055-11-11',
    nextPaymentDate: '2022-04-11',
    nextPaymentAmount: 1350.25,
    outstandingBalance: 1000.5,
    contractType: ContractType.BALLON_CREDIT,
    productName: 'Ballon Credit',
    status: ContractStatus.R,
    brandModelType: 'Volkswagen Golf GTI (1.9TDI)',
    licensePlate: '201-MH-345678',
    vin: 'VW1234567890ABC',
    encryptedVin: 'encrypted-VW1234567890ABC',
    _links: {
        contractDetails: getContractDetailsEndpoint(contractNumber),
        bundledProducts: getBundledProductsEndpoint(contractNumber),
        amortizationDetails: getAmortizationDetailsEndpoint(contractNumber),
        paymentDetails: getPaymentDetailsEndpoint(contractNumber),
        earlySettlement: getEarlySettlementEndpoint(contractNumber),
    },
};

export const BallonCreditWithAllValuesInactive: ContractWithLinks = {
    ...BallonCreditWithAllValuesActive,
    vin: 'VW1234567890IBC',
    encryptedVin: 'encrypted-VW1234567890IBC',
    contractNumber: '0000123456789110',
    encryptedContractNumber: 'ASXDFSHEI120',
    isActive: false,
    status: ContractStatus.R,
};

export const ContractsWithAllValues: ContractWithLinks[] = [
    ConsumerCreditWithAllValuesActive,
    ConsumerCreditWithAllValuesInactive,
    ConsumerCreditWithOverpaymentAlert,
    ConsumerCreditWithCarOwnershipAlert,
    ConsumerCreditWithManageInsurancePoliciesAlert,
    AutoCreditWithAllValuesActive,
    AutoCreditWithAllValuesInactive,
    FinancialLeasingWithAllValuesActive,
    FinancialLeasingWithAllValuesInactive,
    OperativeLeasingWithAllValuesActive,
    OperativeLeasingWithAllValuesInactive,
    BallonCreditWithAllValuesActive,
    BallonCreditWithAllValuesInactive,
];

export const NoContractsAvailable: ContractWithLinks[] = [];

export const ContractsWithAllValidContracts: ContractWithLinks[] = [
    ConsumerCreditWithAllValuesActive,
    AutoCreditWithAllValuesActive,
    FinancialLeasingWithAllValuesActive,
    OperativeLeasingWithAllValuesActive,
    BallonCreditWithAllValuesActive,
];

export const ContractsWithAllInvalidContracts: ContractWithLinks[] = [
    ConsumerCreditWithAllValuesInactive,
    AutoCreditWithAllValuesInactive,
    FinancialLeasingWithAllValuesInactive,
    OperativeLeasingWithAllValuesInactive,
    BallonCreditWithAllValuesInactive,
];

export const ContractsWithMissingValues: ContractWithLinks[] = [
    ConsumerCreditWithMissingValues,
    AutoCreditWithAllValuesInactive,
    FinancialLeasingWithAllValuesActive,
];

export const ContractsNotAllowedForEarlySettlement: ContractWithLinks[] = [
    { ...FinancialLeasingWithAllValuesActive, encryptedContractNumber: 'ASXDFSHEI121' },
];

export const buildContractList = (contracts: ContractWithLinks[]): ContractWithLinks[] => {
    return contracts && contracts.map((contract) => buildContract(contract));
};
