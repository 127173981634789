import { ContractInfo, ContractType } from 'common';

export const ContractsInfoWithAllValues: ContractInfo[] = [
    {
        isActive: true,
        contractNumber: '963284',
        contractType: ContractType.FINANCIAL_LEASING,
        productName: 'Zmluva o finančnom lízingu',
        brandModelType: 'VOLKSWAGEN Touran 1.4 TSI BMT Comfortline DSG 110kW',
    },
    {
        isActive: false,
        contractNumber: '12345',
        contractType: ContractType.FINANCIAL_LEASING,
        productName: 'Zmluva o finančnom lízingu',
        brandModelType: 'VOLKSWAGEN Touran 1.4 TSI BMT Comfortline DSG 110kW',
        statusLabel: 'Riadne ukončená',
    },
    {
        isActive: true,
        contractNumber: '1068419',
        contractType: ContractType.AUTO_CREDIT,
        productName: 'Zmluva o AutoKredite',
        brandModelType: 'AUDI Q7 3.0 TDI quattro tiptronic8 DPF 180kW',
    },
    {
        isActive: false,
        contractNumber: '321123',
        contractType: ContractType.OPERATIVE_LEASING,
        productName: 'Zmluva o OPERATIVE_LEASING',
        brandModelType: 'VW Golf Variant Slovakia Trendline 1.6TDI 81 kW',
    },
    {
        isActive: true,
        contractNumber: '101010',
        contractType: ContractType.AUTO_CREDIT,
        productName: 'Zmluva o AutoKredite',
        brandModelType: 'ŠKODA Octavia Combi 1.6 TDI Style 81kW',
    },
];
