import React from 'react';
import { CalculatedMileage, Mileage } from 'common';
import { Card, Layout, SectionHeading, DefinitionList, Hr, Combobox } from '@vwfs-bronson/bronson-react';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';
import {
    DefinitionList as DefinitionListShared,
    DefinitionListItem as DefinitionListItemShared,
} from '@cp-shared-5/frontend-ui';
import { CurrentTerms } from '../ChangeMileageTypes';

export type ComparisonSectionProps = {
    mileageAvailable?: Mileage;
    currentTerms?: CurrentTerms;
    calculatedTerms?: CalculatedMileage;
    chosenOption?: number;
    setChosenOption: Function;
    setFieldValue: Function;
    validationError?: string;
};

export const ComparisonSection: React.FC<ComparisonSectionProps> = ({
    mileageAvailable,
    currentTerms,
    calculatedTerms,
    setChosenOption,
    setFieldValue,
    validationError,
}) => {
    const { t, f } = useTranslationWithFormatting('change-mileage');

    const newContractTermsList: DefinitionListItemShared[] = [
        {
            label: t('calculate.new-terms.monthly-rate'),
            value: (
                <span className={calculatedTerms && 'u-text-success'}>
                    {calculatedTerms?.newMonthlyRate
                        ? f(calculatedTerms?.newMonthlyRate, TranslationFormat.CURRENCY)
                        : '-'}
                </span>
            ),
        },
        {
            label: t('calculate.new-terms.last-installment'),
            value: (
                <span className={calculatedTerms && 'u-text-success'}>
                    {calculatedTerms?.newLastIstallment
                        ? f(calculatedTerms?.newLastIstallment, TranslationFormat.CURRENCY)
                        : '-'}
                </span>
            ),
        },
    ].filter((item) => !!item.value);

    const currentContractTermsList: DefinitionListItemShared[] = [
        {
            label: t('calculate.current-terms.current-mileage'),
            value: currentTerms?.currentMileage ? f(currentTerms?.currentMileage, TranslationFormat.DISTANCE) : '-',
        },
        {
            label: t('calculate.current-terms.monthly-rate'),
            value: currentTerms?.monthlyRate ? f(currentTerms?.monthlyRate, TranslationFormat.CURRENCY) : '-',
        },
        {
            label: t('calculate.current-terms.last-installment'),
            value: currentTerms?.lastInstallment ? f(currentTerms?.lastInstallment, TranslationFormat.CURRENCY) : '-',
        },
    ].filter((item) => !!item.value);

    return (
        <Card element="article" className="u-text-left">
            <SectionHeading level="3" subtitle={t('calculate.text')}>
                {t('calculate.headline')}
            </SectionHeading>
            <Layout equalHeight>
                <Layout.Item default="5/12" s="1/1">
                    <div>
                        <h6>{t('calculate.new-terms.headline')}</h6>
                        <DefinitionList>
                            <DefinitionList.Item title={t('calculate.new-terms.new-mileage')}>
                                <div style={{ width: '50%' }}>
                                    <Combobox
                                        testId={'mileageDropdown'}
                                        icon="semantic-expand"
                                        name="newMileageAmount"
                                        label=""
                                        onChange={async (mileage: string) => {
                                            await setFieldValue('newMileageAmount', parseInt(mileage));
                                            setChosenOption(parseInt(mileage));
                                        }}
                                    >
                                        {mileageAvailable?.mileageAmountField &&
                                            mileageAvailable?.mileageAmountField.map(
                                                (mileage: number, index: number) => (
                                                    <Combobox.Item
                                                        testId={`mileage-item-${index}`}
                                                        optionValue={mileage.toString()}
                                                        optionKey={mileage.toString()}
                                                        key={index}
                                                    >
                                                        <span>{f(mileage, TranslationFormat.DISTANCE)}</span>
                                                    </Combobox.Item>
                                                ),
                                            )}
                                    </Combobox>
                                </div>
                                <span className="c-error-message" style={{ fontWeight: 400 }}>
                                    {validationError && validationError}
                                </span>
                            </DefinitionList.Item>
                        </DefinitionList>
                        <DefinitionListShared list={newContractTermsList} />
                    </div>
                </Layout.Item>
                <Layout.Item default="1/12" s="1/1" className="u-border-r u-hide@s u-mh-xsmall" />

                <Hr className="u-hide@s-up u-mb-xsmall" />

                <Layout.Item default="5/12" s="1/1">
                    <div>
                        <h6>{t('calculate.current-terms.headline')}</h6>
                        <DefinitionListShared list={currentContractTermsList} />
                    </div>
                </Layout.Item>
            </Layout>
        </Card>
    );
};
