import React from 'react';
import { VehicleDetails } from 'common';
import { DefinitionList, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from 'localization/useTranslationWithFormatting';

type GeneralSectionProps = {
    vehicleDetails: VehicleDetails;
};

export const GeneralSection: React.FC<GeneralSectionProps> = ({ vehicleDetails }) => {
    const { t, f } = useTranslationWithFormatting('vehicle-details');

    const { brandModelType, vin, registrationDate } = vehicleDetails;

    const itemList: DefinitionListItem[] = [
        {
            label: t('brand-model-type'),
            value: brandModelType || '-',
            testId: 'vehicle-details-brand',
        },
        {
            label: t('vin'),
            value: vin || '-',
            testId: 'vehicle-details-vin',
        },
        {
            label: t('registration-date'),
            value: registrationDate ? f(registrationDate, TranslationFormat.DATE) : '-',
            testId: 'vehicle-details-registration-date',
        },
    ];

    return <DefinitionList split={true} list={itemList} />;
};
