import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Contract } from 'common';
import { overpaymentManagementPath } from 'components/navigation/paths';
import { AlertNotification } from '../alert-notification';

export type OverpaymentAlertNotificationProps = {
    contract: Contract;
};

export const OverpaymentAlertNotification: React.FC<OverpaymentAlertNotificationProps> = ({ contract }) => {
    const { t } = useTranslation('contracts');
    const history = useHistory();

    if (!contract.overpaymentAlert?.headline && !contract.overpaymentAlert?.description) return null;

    const handleClick = () => {
        history.push(overpaymentManagementPath(contract.encryptedContractNumber));
    };

    return (
        <AlertNotification
            headline={contract.overpaymentAlert.headline}
            text={contract.overpaymentAlert.description}
            buttonLabel={t('overpayment-alert-button-text')}
            onClick={handleClick}
            testId={'overpayment-alert'}
        />
    );
};
