import React, { useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { VehicleDetails, EditStatus } from 'common';
import { changeLicensePlatePath } from 'components/navigation/paths';
import { ConsultView } from './consult-view';
import { EditView } from './edit-view';

type LicensePlateSectionProps = { vehicleDetails: VehicleDetails; encryptedContractNumber: string };

export const LicensePlateSection: React.FC<LicensePlateSectionProps> = ({
    vehicleDetails,
    encryptedContractNumber,
}) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [lastEditStatus, setLastEditStatus] = useState<EditStatus>(EditStatus.NOT_PERFORMED);

    const startEditing = (): void => {
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changeLicensePlatePath(encryptedContractNumber));
    };

    const finishEditing = (lastEditStatus: EditStatus): void => {
        setLastEditStatus(lastEditStatus);
        history.push(path);
    };

    return (
        <Switch>
            <Route exact path={changeLicensePlatePath(encryptedContractNumber)}>
                <EditView finishEditing={finishEditing} encryptedContractNumber={encryptedContractNumber} />
            </Route>
            <Route path={path}>
                <ConsultView
                    vehicleDetails={vehicleDetails}
                    lastEditStatus={lastEditStatus}
                    startEditing={startEditing}
                />
            </Route>
        </Switch>
    );
};
