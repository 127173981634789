import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { areAllFilesValid } from '../../../../file-upload/helpers';
import { PrivateIdentificationError, privateIdentificationValidator } from 'common';

export const validationSchema = (t: TFunction): Yup.AnySchema => {
    const translationPrefix = 'identification.edit-view.private.validation-errors';
    const privateIdentificationError: PrivateIdentificationError = {
        title: { max: t(`${translationPrefix}.title.max-length`) },
        firstName: {
            max: t(`${translationPrefix}.first-name.max-length`),
            required: t(`${translationPrefix}.first-name.missing`),
        },
        surname: {
            max: t(`${translationPrefix}.surname.max-length`),
            required: t(`${translationPrefix}.surname.missing`),
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        filesInfo: undefined,
        idCard: {
            type: {
                oneOf: t(`${translationPrefix}.id-card-type.missing`),
                required: t(`${translationPrefix}.id-card-type.missing`),
            },
            number: {
                unknownType: t(`${translationPrefix}.id-card-number.invalid`),
                matches: t(`${translationPrefix}.id-card-number.invalid`),
                required: t(`${translationPrefix}.id-card-number.invalid`),
                requiredForPassport: t(`${translationPrefix}.id-card-number.required-for-passport`),
            },
            issueDate: {
                valid: t(`${translationPrefix}.id-card-issue-date.missing`),
                required: t(`${translationPrefix}.id-card-issue-date.missing`),
            },
            issueCity: {
                required: t(`${translationPrefix}.id-card-issue-city.missing`),
            },
        },
    };
    return privateIdentificationValidator(privateIdentificationError)
        .omit(['files'])
        .shape({
            frontOfDocument: Yup.array()
                .min(1, t('file-upload:mandatory-error'))
                .max(1, t('file-upload:max-one-file-error'))
                .test('files', '', (files) => areAllFilesValid(files as File[])),
            backOfDocument: Yup.array()
                .max(1, t('file-upload:max-one-file-error'))
                .test('files', '', (files) => areAllFilesValid(files as File[])),
        });
};
