import { openInNewWindow, useAnalyticsActionTracker } from '@cp-shared-5/frontend-ui';
import { MarketingCard } from 'common';
import { Button, Card, Layout } from '@vwfs-bronson/bronson-react';
import React from 'react';

export const MarketingCardUi: React.FC<{ marketingCards?: MarketingCard[] }> = ({ marketingCards }) => {
    const { onAction } = useAnalyticsActionTracker('onLearnMore');

    if (!marketingCards) {
        return null;
    }

    return (
        <Layout>
            {marketingCards.map(({ title, imageUrl, text, buttonLabel, buttonUrl }, index) => (
                <Layout.Item key={index}>
                    <Card
                        element="article"
                        title={title || ''}
                        imageSrc={imageUrl || ''}
                        footer={true}
                        buttons={[
                            <Button
                                key={buttonUrl || 'isLoading'}
                                link={true}
                                href={buttonUrl}
                                icon="semantic-forward"
                                iconReversed
                                small
                                onClick={() => {
                                    openInNewWindow(buttonUrl)();
                                    onAction(title);
                                }}
                            >
                                {buttonLabel}
                            </Button>,
                        ]}
                    >
                        {text}
                    </Card>
                </Layout.Item>
            ))}
        </Layout>
    );
};
